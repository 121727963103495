import React, { useState, useEffect, useRef } from 'react';
import '../styles/globalMessage.scss';
import UseTracking from '../utils/UseTracking';

const GlobalMessage = ({ text }) => {
  const [isShown, setIsShown] = useState(false);
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    setIsShown(true);
    UseTracking({
      eventCategory: 'page-click',
      eventAction: 'global-message-shown',
      eventLabel: text
    });
  }, []);

  function getClass() {
    let name = 'global-message';
    if (isShown) {
      return `${name}__show`;
    } else if (isHidden) {
      return `${name}__hide`;
    }
  }

  function hideMessage() {
    setIsShown(false);
    setIsHidden(true);
    UseTracking({
      eventCategory: 'page-click',
      eventAction: 'global-message-hide',
      eventLabel: text
    });
  }

  return (
    <>
      <div className={`global-message ${getClass()}`}>
        {text}
        <span className='global-message-close' onClick={hideMessage}>
          &#215;
        </span>
      </div>
    </>
  );
};

GlobalMessage.propTypes = {};

export default GlobalMessage;
