import React, { useState, useEffect, useRef } from "react";
import "../styles/draggableModal.scss";

export default function DraggableModal({
  toggleModal = false,
  visibleContentRef,
  headerRef,
  elementOverModal,
  isExpandable,
  ...props
}) {
  const [isExpanded, toggleIsExpanded] = useState(false);
  const [verticalPositionOnStart, setVerticalPositionOnStart] = useState(false);

  const dragContainer = useRef(null);
  const locateUserRer = useRef(null);
  const visibleModalPart = useRef(null);

  function handleDragStart(toggleOn) {
    if (!isExpandable) {return};
    toggleIsExpanded(toggleOn);
  }

  function touchStart (e) {
    let currentVerticalPosition = e.nativeEvent.touches[0].pageY;
    setVerticalPositionOnStart(currentVerticalPosition)
  }

  function touchMove (e) {
    if (!verticalPositionOnStart) {return};
    let currentVerticalPosition = e.nativeEvent.touches[0].pageY;

    let toggleOn = (currentVerticalPosition - verticalPositionOnStart) < 0;

    if (Math.abs(currentVerticalPosition - verticalPositionOnStart) > 50) {
      handleDragStart(toggleOn);
      setVerticalPositionOnStart(false)
    }
  }

  useEffect(() => {
    toggleIsExpanded(toggleModal);
  }, [toggleModal]);

  useEffect(() => {
    let heightOfVisibleArea = 0; //initiall padding

    if (headerRef.current) {
      heightOfVisibleArea += headerRef.current.offsetHeight;
    }

    if (dragContainer.current) {
      heightOfVisibleArea += dragContainer.current.offsetHeight;
    }

    if (visibleContentRef.current) {
      let elementBottom = visibleContentRef.current.getBoundingClientRect().bottom;
      let modalTop = visibleModalPart.current.getBoundingClientRect().top;
      heightOfVisibleArea += (elementBottom - modalTop - (headerRef.current.offsetHeight + dragContainer.current.offsetHeight));
    }
    if (locateUserRer && visibleModalPart && visibleModalPart.current) {
      visibleModalPart.current.style.top = `calc(100% - ${heightOfVisibleArea}px)`
      locateUserRer.current.style.bottom = `${heightOfVisibleArea}px`
    }
  }, [visibleContentRef.current, headerRef.current]);

  return (
    <div>
      <div ref={locateUserRer} className='p-absolute'>
        {elementOverModal}
      </div>
      <div
        className={`modal-container ${isExpanded ? "modal-expanded" : ""} ${!isExpandable && ' pt-10'}`}
        ref={visibleModalPart}
        onClick={handleDragStart.bind(null, !isExpanded)}
      >
        {isExpandable &&
          <div  className="drag-container" onTouchStart={touchStart} onTouchMove={touchMove} ref={dragContainer}>
            <div  className="drag-indicator"></div>
          </div>
        }
        {props.children}
      </div>
    </div>
  );
}
