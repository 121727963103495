import React, { useState, useEffect, useRef } from 'react';

export default function UseUserFeedback() {
  const userId = window.localStorage.getItem('lad-id');
  const [response, setResponse] = useState();
  const [allMessages, setAllMessages] = useState();
  const [messageToShow, setMessageToShow] = useState([]);

  function getUserMessages() {
    fetch(`https://api.lad.lviv.ua/feedback/${userId}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setAllMessages([...data]);
        setResponse(data.filter((item) => item.is_response));
      });
  }

  function showResponse() {
    let messages = [];
    response.forEach((message) => {
      if (!wasShown(message.date)) {
        const shownResponses =
          JSON.parse(window.localStorage.getItem('shownResponses')) || {};
        messages = [...messages, message];
        shownResponses[message.date] = true;
        window.localStorage.setItem(
          'shownResponses',
          JSON.stringify(shownResponses)
        );
      }
    });
    setMessageToShow(messages.reverse());
  }

  function wasShown(messageDate) {
    const shownResponses = JSON.parse(
      window.localStorage.getItem('shownResponses')
    );
    if (shownResponses) {
      return shownResponses[messageDate];
    }
    return false;
  }

  useEffect(() => {
    getUserMessages();
  }, []);

  useEffect(() => {
    if (response) {
      showResponse();
    }
  }, [response]);

  return { messageToShow, allMessages };
}
