import L from 'leaflet';

let mapMoveCallback;
let map = null;
const defaultZoom = 17;
const defaultPosition = {
  lat: 49.842957,
  lon: 24.031111,
};
let locationMarker;
let usermarkerOuter;
let mapContainer;

function MapWrapper() {
  function _initialize({
    container,
    zoom,
    showCurrentLocation,
    hideZoom,
    onMoveCallBack = () => {},
  }) {
    mapContainer = container;
    mapMoveCallback = onMoveCallBack;
    map = L.map(container, {
      zoomControl: false,
    }).setView(defaultPosition, defaultZoom);
    zoom && map.setZoom(zoom);

    !hideZoom && L.control.zoom({ position: 'topright' }).addTo(map);

    const isDarkTheme = window.localStorage.getItem('user-theme') === 'dark-theme';

    L.tileLayer(
      isDarkTheme
        ? 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png'
        : 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
    ).addTo(map);

    if (showCurrentLocation) {
      _createCurrentLocationMarker();
      _loadLastUsedLocation();
    }
    _bindEvents();
  }

  function _bindEvents() {
    let preVClassName = '';
    let mapContainerElement = document.getElementById(mapContainer);

    map.on('moveend', (ev) => {
      let { lat, lng } = map.getCenter();
      _setUserLocation({ lat, lon: lng });
    });

    map.on('zoomend', (e) => {
      let currentZoomLevel = map.getZoom();
      preVClassName && mapContainerElement.classList.remove(preVClassName);
      mapContainerElement.classList.add(`zoom-value-${currentZoomLevel}`);
      preVClassName = `zoom-value-${currentZoomLevel}`;
    });
  }

  function _updateUserLocation(location) {
    mapMoveCallback(location);
    map.setView(location, 17);
    locationMarker.setLatLng(location);
    usermarkerOuter.setLatLng(location);
  }

  function _setUserLocation(location) {
    mapMoveCallback(location);
  }

  function _createCurrentLocationMarker() {
    locationMarker = createCircleMarker({
      color: '#ffff',
      fillColor: '#5672ff',
      fill: 'false',
      fillOpacity: 1,
      radius: 7,
      className: 'your-location',
    });
    usermarkerOuter = createCircleMarker({
      color: '#5672ff',
      fillOpacity: 0.3,
      stroke: false,
      radius: 25,
      className: 'your-location',
    });
  }

  function createCircleMarker(options, location = defaultPosition) {
    return L.circleMarker(location, options).addTo(map);
  }

  function _createDivIcon({ className, html, iconSize }) {
    var divIcon = L.divIcon({ className, html, iconSize });
    return divIcon;
  }

  function _createMarker({
    location,
    iconUrl,
    iconSize = [38, 95],
    text = '',
    callBack,
    html = '',
    className,
  }) {
    let icon;

    if (className) {
      icon = _createDivIcon({ className, html, iconSize });
    }

    if (iconUrl) {
      icon = L.icon({
        iconUrl,
        iconSize,
      });
    }

    let marker = L.marker(location, icon ? { icon } : '');
    marker.addTo(map);
    text && marker.bindPopup(text);
    callBack && marker.on('click', callBack);

    return marker;
  }

  function _loadLastUsedLocation(force) {
    let locationFromLocalStorage = JSON.parse(window.localStorage.getItem('lastUserLocation'));

    if (locationFromLocalStorage && !force) {
      _updateUserLocation(locationFromLocalStorage);
    }
  }

  function _getCurrentUserLocation(force) {
    var options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

    function error(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    }

    let success = (position) => {
      let location = {};
      location.lat = position.coords.latitude;
      location.lon = position.coords.longitude;
      _updateUserLocation(location);
    };
    navigator.geolocation.getCurrentPosition(success, error, options);
  }

  function _setMapLocation(location, zoom) {
    map.setView(location, zoom);
  }

  function _fitMapBounds(bounds) {
    map.fitBounds(bounds, { padding: [25, 25] });
  }

  function _resize() {
    map.invalidateSize();
  }

  function _panBy(pixels) {
    map.panBy(pixels);
  }

  function _containerPointToLatLng(pixels) {
    return map.unproject(pixels);
  }

  function _drawPolyLine({ coordinates, color, fitBounds, weight = 3 }) {
    var Polyline = L.polyline(coordinates, { color, smoothFactor: 5, weight });
    Polyline.addTo(map);
    fitBounds && map.fitBounds(Polyline.getBounds());
  }

  return {
    init: _initialize,
    getCurrentUserLocation: _getCurrentUserLocation,
    createMarker: _createMarker,
    setMapLocation: _setMapLocation,
    drawPolyLine: _drawPolyLine,
    createCircleMarker: createCircleMarker,
    createDivIcon: _createDivIcon,
    fitMapBounds: _fitMapBounds,
    updateUserLocation: _updateUserLocation,
    panBy: _panBy,
    containerPointToLatLng: _containerPointToLatLng,
    resize: _resize,
  };
}

export default MapWrapper;
