import React, { useState, useEffect } from "react";
import "../styles/tabs.scss";

export default function Tabs({ activeIndex, visibleContentRef, ...props }) {
  const [activeTab, setActiveTab] = useState(activeIndex || 0);

  useEffect(() => {
    let activeLabel = "";
    if (activeTab === 0) {
      activeLabel = "stop_tab";
    } else if (activeTab === 2) {
      activeLabel = "route_tab";
    }
  }, [activeTab]);

  function renderTabs() {
    return React.Children.map(props.children, (item, i) => {
      if (i % 2 === 0) {
        let active = activeTab === i ? "active" : "";
        return (
          <a onClick={() => setActiveTab(i)} className={`${active} tab`}>
            {item}
          </a>
        );
      }
    });
  }

  function renderContent() {
    return React.Children.map(props.children, (item, i) => {
      if (i - 1 === activeTab) {
        return <div className="content">{item}</div>;
      } else {
        return;
      }
    });
  }

  return (
    <div className="tabs" ref={visibleContentRef}>
      {renderTabs()}
      {renderContent()}
    </div>
  );
}
