export default function sendTrackingEvent({
  eventCategory,
  eventAction,
  eventLabel,
}) {
  if (!window.ga) {
    return;
  }
  window.ga("send", {
    hitType: "event",
    eventCategory,
    eventAction,
    eventLabel,
  });
}
