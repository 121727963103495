import React from "react";

export default function ({ width = "30px", color, className, ...props }) {
  return (
    <svg
      className={className}
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      fill={color}
      width={width}
    >
      <g>
        <g>
          <path
            d="M189.543,293c-15.99,0-29,13.009-29,29c0,15.991,13.009,29,29,29c15.99,0,29-13.009,29-29
			C218.543,306.009,205.533,293,189.543,293z M189.543,331c-4.963,0-9-4.038-9-9c0-4.962,4.037-9,9-9c4.963,0,9,4.038,9,9
			C198.543,326.962,194.506,331,189.543,331z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M322.459,293c-15.99,0-29,13.009-29,29c0,15.991,13.009,29,29,29c15.99,0,29-13.009,29-29
			C351.459,306.009,338.449,293,322.459,293z M322.459,331c-4.963,0-9-4.038-9-9c0-4.962,4.037-9,9-9s9,4.038,9,9
			C331.459,326.962,327.422,331,322.459,331z"
          />
        </g>
      </g>
      <g>
        <g>
          <path d="M287.501,80h-64c-5.522,0-10,4.477-10,10s4.477,10,10,10h64c5.522,0,10-4.477,10-10S293.023,80,287.501,80z" />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M204.22,213.28c-1.87-1.86-4.45-2.92-7.07-2.92c-2.64,0-5.21,1.06-7.08,2.92c-1.859,1.87-2.93,4.45-2.93,7.08
			s1.07,5.2,2.93,7.07c1.87,1.86,4.44,2.93,7.08,2.93c2.62,0,5.2-1.07,7.07-2.93c1.86-1.87,2.92-4.44,2.92-7.07
			S206.081,215.15,204.22,213.28z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M509.07,477.07c-1.859-1.86-4.439-2.92-7.069-2.92s-5.21,1.06-7.07,2.92c-1.86,1.87-2.93,4.44-2.93,7.08
			c0,2.63,1.069,5.2,2.93,7.07c1.86,1.86,4.44,2.93,7.07,2.93s5.21-1.07,7.069-2.93c1.86-1.86,2.931-4.44,2.931-7.07
			C512.001,481.51,510.93,478.94,509.07,477.07z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M485.373,453.377l-97.418-97.418C401.588,341.831,410,322.632,410,301.495v-182.99C410,75.217,374.783,40,331.495,40H266
			V10c0-5.523-4.478-10-10-10c-5.522,0-10,4.477-10,10v30h-65.495C137.217,40,102,75.217,102,118.505v182.989
			c0,21.137,8.412,40.336,22.045,54.464L2.929,477.075c-3.905,3.905-3.905,10.237,0,14.142c1.953,1.953,4.512,2.929,7.071,2.929
			s5.119-0.977,7.071-2.929l122.661-122.66c2.998,1.83,6.135,3.451,9.379,4.872l-56.045,82.9c-0.008,0.012-0.016,0.024-0.024,0.036
			L65.976,496.4c-3.094,4.575-1.892,10.792,2.684,13.885c1.717,1.161,3.664,1.717,5.592,1.717c3.209,0,6.36-1.542,8.293-4.4
			l24.11-35.663h298.691l24.11,35.663c1.933,2.859,5.084,4.4,8.293,4.4c1.928,0,3.875-0.556,5.592-1.717
			c4.575-3.093,5.777-9.31,2.684-13.885l-27.066-40.035c-0.008-0.012-0.016-0.024-0.024-0.036l-56.045-82.9
			c3.244-1.421,6.381-3.042,9.379-4.872l98.962,98.963c1.953,1.953,4.512,2.929,7.071,2.929s5.118-0.976,7.071-2.929
			C489.278,463.615,489.278,457.283,485.373,453.377z M122.001,118.505c0-32.259,26.245-58.505,58.504-58.505h150.99
			C363.755,60,390,86.246,390,118.505V120h-24.995c-0.003,0-0.007,0-0.01,0h-77.49c-0.003,0-0.007,0-0.01,0H122.001V118.505z
			 M390,140v124H245.143l124-124H390z M122.001,140h141.357l-40.179,40.179c-3.905,3.905-3.905,10.237,0,14.143
			c1.953,1.953,4.512,2.929,7.071,2.929s5.118-0.976,7.071-2.929L291.643,140h49.215L218.929,261.929
			c-0.632,0.632-1.151,1.333-1.578,2.071h-95.35V140z M391.824,451.938H120.176l13.521-20h244.606L391.824,451.938z
			 M364.782,411.938H147.219l22.136-32.743c3.645,0.521,7.364,0.806,11.15,0.806h150.99c3.786,0,7.506-0.285,11.15-0.806
			L364.782,411.938z M331.496,360h-150.99c-32.26,0-58.505-26.246-58.505-58.505V284h268v17.495
			C390.001,333.755,363.756,360,331.496,360z"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
}
