import React from "react";
import LocateMarker from "../icons/components/LocateMarker";
import BusIcon from "../icons/components/BusIcon";
import TrolleyIcon from "../icons/components/TrolleyIcon";
import TramIcon from "../icons/components/TramIcon";
import UseTracking from '../utils/UseTracking';
import { Link } from 'react-router-dom';
import Schedule from '../icons/components/Schedule';
import "../styles/lineCard.scss";

export default function ({
  data,
  onClickCallback,
  className,
  hideTimeLeft,
  isInStack,
  removeTimeLeft,
  isFirst,
  ...props
}) {
  let timeLeft =
    !hideTimeLeft && !removeTimeLeft && data.time_left.split("хв")[0];
  let vehicleType = data.vehicle_type;
  let isTimeRunningout = timeLeft === "< 1";

  return (
    <div
      className={`card-wrapper ${className}`}
    >
      {!removeTimeLeft && (
        <div
          className={`card-wrapper-info ${isTimeRunningout && "danger"} ${
            hideTimeLeft && "visibility-h"
          }`}
        >
          <span>{timeLeft}хв.</span>

          {isFirst  &&
          <Link
            to={`/route/${data.route}/schedule`}
            onClick={() => {
              UseTracking({
                eventCategory: 'page-click',
                eventAction: 'route_map_click',
                eventLabel: 'schedule_button_click',
              });
            }}
            >
            <Schedule width={25} height={25} className={'schedule-icon.small'} />
          </Link>
          }

        </div>
      )}

      <div className={`card card-${vehicleType || "bus"}`}
        onClick={() => {
          if (isInStack) {
            return;
          }
          onClickCallback.call(this, data.route, data.vehicle_id);
        }}
      >
        <div className="card-description">
          <span
            className="badge-filled"
            style={{ backgroundColor: data.color }}
          >
            {data.route}
          </span>
          <span className="subtitle">{data.end_stop}</span>
        </div>

        <div
          className="card-icons-block"
          onClick={onClickCallback.bind(this, data.route, data.vehicle_id)}
        >
          {data.lowfloor && (
            <div className="card-icon m-right-15">
              <span className={`icon icon-accessability`}></span>
            </div>
          )}
          <LocateMarker color={data.color} width="25px" className="mr-1" />

          {vehicleType === "bus" && <BusIcon width="30px" color={data.color} />}
          {vehicleType === "tram" && (
            <TramIcon width="35px" color={data.color} />
          )}
          {vehicleType === "trol" && (
            <TrolleyIcon width="30px" color={data.color} />
          )}

        </div>
      </div>
    </div>
  );
}
