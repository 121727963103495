/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import InfoBlock from '../components/InfoBlock';
import PageHeader from '../components/PageHeader';
import StackedCards from '../components/StackedCards';
import FullScreen from '../icons/components/FullScreen';
import HeartFilled from '../icons/components/HeartFilled';
import Minimize from '../icons/components/Minimize';
import MapWrapper from '../utils/MapWrapper';
import UsePageActive from '../utils/UsePageActive';
import UseTracking from '../utils/UseTracking';
import UseUUID from '../utils/UseUUID';
import UseUserInactive from '../utils/UseUserInactive';
import hexToRGB from '../utils/hexToRgba';

import '../App.scss';
import '../styles/detailedPage.scss';

let marker = null;
let allNodesonMap = [];

export default function (props) {
  let { stopId } = useParams();
  const [stopName, setStopName] = useState('...');
  const [stopCode, setStopCode] = useState('');
  const [stopLocation, setStopLocation] = useState();
  const [timetable, setTimetable] = useState([]);
  const [stopTransfers, setStopTransfers] = useState([]);
  const [nearestBuses, setNearestBuses] = useState([]);
  const [noLinesAlert, setNoLinesAlert] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [allFavorites, setAllFavorites] = useState([]);
  const [enlargedMap, setEnlargedMap] = useState(false);
  const [uniqueRoutes, setUniqueRoutes] = useState([]);
  const history = useHistory();
  const isCancelled = useRef(false);

  const TIMEOUT_TO_UPDTE_FROM_API = 10;
  const USER_INACTIVE_TIMEOUT = 180;
  let mainMapInstance = MapWrapper();

  let { timerTime, setTimerPaused } = UseUserInactive({
    callback: handleUserLocationSet,
    userTimeout: USER_INACTIVE_TIMEOUT,
    callbackTimeout: TIMEOUT_TO_UPDTE_FROM_API,
    pageName: 'STOP_DETAILS_PAGE',
  });
  const isPageActive = UsePageActive();
  UseUUID();

  useEffect(() => {
    setTimerPaused(!isPageActive);
  }, [isPageActive]);

  useEffect(() => {
    getStopStaticdata();
    handleUserLocationSet();

    mainMapInstance.init({
      container: 'details-map',
      showCurrentLocation: false,
      hideZoom: true,
    });
    marker = mainMapInstance.createMarker({
      location: {
        lat: 49.842957,
        lon: 24.031111,
      },
      iconSize: [38, 95],
      html: `<span class="marker-inner"></span>`,
      className: `bus-stop-marker`,
    });

    let stopsFromLocalStorage = JSON.parse(window.localStorage.getItem('favoriteStops'));

    if (!stopsFromLocalStorage) {
      return;
    }
    setAllFavorites(stopsFromLocalStorage);

    const isFavorite = stopsFromLocalStorage.findIndex((item) => item.code == stopId) > -1;
    setIsFavorite(isFavorite);

    return () => {
      isCancelled.current = true;
    };
  }, []);

  useEffect(() => {
    if (uniqueRoutes.length) {
      return;
    }
    const routes = [...new Set(stopTransfers.map((item) => item.id))];
    setUniqueRoutes(routes);
  }, [stopTransfers]);

  useEffect(() => {
    reDrawBuses();
  }, [nearestBuses, stopLocation]);

  useEffect(() => {
    fetchRouteCoordinates(uniqueRoutes);
  }, [uniqueRoutes, timetable]);

  function drawLines(routesLines) {
    let allDirections = {};

    stopTransfers.forEach((route) => {
      if (!allDirections[route.id]) {
        allDirections[route.id] = route.direction_id;
      }
    });

    routesLines
    .filter(l => l.type != 'bus')
    .map((line) => {
      const lineName = line.id;
      const direction = allDirections[lineName] || 0;
      const routeFromStop = findRouteFromStop(line.shapes[direction]);
      let weight = 2;
      timetable.forEach((item) => {
        const routeName = item.route_id;
        if (lineName === routeName) {
          weight += 1;
        }
      });

      mainMapInstance.drawPolyLine({
        coordinates: routeFromStop,
        color: hexToRGB(line.color, 0.5),
        weight: weight,
      });
      mainMapInstance.createMarker({
        location: routeFromStop[routeFromStop.length - 1],
        iconSize: [5, 5],
        html: `<span class="end-of-route" style="border-color: ${line.color}"></span>`,
        className: `route`,
      });
    });
  }

  function findRouteFromStop(lineShape) {
    let closestPoint = { distance: 10000, coordinates: [], index: 0 };

    lineShape.forEach(
      (lineCoordinates, index) => {
        const X = lineCoordinates[0] - stopLocation.lat;
        const Y = lineCoordinates[1] - stopLocation.lon;

        const currentDistance = Math.sqrt(Math.pow(X, 2) + Math.pow(Y, 2));
        if (currentDistance < closestPoint.distance) {
          closestPoint.distance = currentDistance;
          closestPoint.index = index;
          closestPoint.coordinates = lineCoordinates;
        }
      },
      [0, 0],
    );
    const index = lineShape.findIndex(
      (coordinates) =>
        coordinates[0] === closestPoint.coordinates[0] &&
        coordinates[1] === closestPoint.coordinates[1],
    );

    return lineShape.slice(index, lineShape.length);
  }

  function fetchRouteCoordinates(routes) {
    if (!routes) {
      return;
    }
    const requests = routes.map((routeId) =>
      fetch(`https://api.lad.lviv.ua/routes/static/${routeId}`),
    );

    Promise.all(requests)
      .then((allResponses) => {
        if (!allResponses) {
          return;
        }
        return Promise.all(allResponses.map((r) => r.json()));
      })
      .then((data) => {
        if (isCancelled.current) {
          return;
        }
        drawLines(data);
      });
  }

  function getStopStaticdata() {
    return fetch(`https://api.lad.lviv.ua/stops/${stopId}/static`)
      .then((response) => {
        if (response.status === 404) {
          history.push(`/not-found`);
        }
        return response.json();
      })
      .then((data) => {
        if (isCancelled.current) {
          return;
        }

        setStopName(data.name);
        setStopCode(data.code);
        setStopTransfers(data.transfers);

        const location = {
          lat: data.latitude,
          lon: data.longitude,
        };
        setStopLocation(location);
        marker.setLatLng(location);
        if (!enlargedMap) {
          mainMapInstance.setMapLocation(location);
        }
        window.localStorage.setItem('lastUserLocation', JSON.stringify(location));

        document.title = `Транспорт на зупинці ${data.name} (${data.code}), Львів`;
        document.description = `Розклад транспорту на зупинці ${data.name}, коли приїде маршрутка, автобус, тролейбус, трамвай, транспорт на карті`;
      });
  }

  function handleUserLocationSet() {
    return fetch(
      `https://api.lad.lviv.ua/stops/${stopId}/timetable`,
      // `https://stg-api.lad.lviv.ua/stops/${stopId}`
    )
      .then((response) => {
        if (response.status === 404) {
          history.push(`/not-found`);
        }
        return response.json();
      })
      .then((data) => {
        if (isCancelled.current) {
          return;
        }
        setTimetable(data);
        setNearestBuses(
          data.map((item) => {
            return {
              vehicleId: item.vehicle_id,
              location: item.location,
              bearing: item.bearing,
              route: item.route,
              color: item.color,
            };
          }),
        );
        setNoLinesAlert(!data.length);
      });
  }

  function reDrawBuses() {
    if (!nearestBuses.length) {
      return;
    }
    allNodesonMap.forEach((busItem) => {
      busItem.remove();
    });

    nearestBuses.forEach((item, index) => {
      let bearing = item.bearing - 90;
      if (!item.location) {
        return;
      }
      let isUpcoming = false;

      if (index < 3) {
        isUpcoming = true;
      }
      const markerOpacity = isUpcoming ? 1 : 0.3;
      allNodesonMap.push(
        mainMapInstance.createMarker({
          location: item.location,
          iconSize: [25, 25],
          html: `<div class="marker-bus-inner" style="transform: rotate(${bearing}deg); background-color: ${item.color}; opacity: ${markerOpacity}">&#10148;</div><span class="label" style="opacity: ${markerOpacity}">${item.route}</span></div>`,
          className: `bus-marker bus-marker-id-`,
          callBack: () => {
            UseTracking({
              eventCategory: 'page-click',
              eventAction: 'stop_details_map_click',
              eventLabel: 'vehicle_marker_click',
            });
            history.push(`/vehicle/${item.vehicleId}`);
          },
        }),
      );
    });
    let boundsToFit = nearestBuses.reduce((acc, item) => [...acc, item.location], []).splice(0, 2);
    if (!enlargedMap) {
      stopLocation && boundsToFit.push([stopLocation.lat, stopLocation.lon]);
      mainMapInstance.fitMapBounds(boundsToFit);
    }
  }

  function calculateTimeTableRender() {
    if (noLinesAlert) {
      return <InfoBlock>Найближчим часом нічого не приїде :(</InfoBlock>;
    }

    if (timetable.length) {
      return <StackedCards timetable={timetable} />;
    } else {
      return <InfoBlock>Думаю...</InfoBlock>;
    }
  }

  function fabBtnClick(toggleOn) {
    if (!stopCode) {
      return;
    }
    setIsFavorite(toggleOn);
    const stops = [...allFavorites];
    if (toggleOn) {
      stops.push({ name: stopName, code: stopCode });
    } else {
      let indexToRemove = stops.findIndex((item) => item.code === stopId);
      stops.splice(indexToRemove, 1);
    }
    window.localStorage.setItem('favoriteStops', JSON.stringify(stops));
    UseTracking({
      eventCategory: 'page-click',
      eventAction: 'bus_stop_heart_click',
      eventLabel: toggleOn ? 'add' : 'remove',
    });
    setAllFavorites(stops);
  }

  function enlargeMap() {
    setEnlargedMap((enlargedMap) => !enlargedMap);
    UseTracking({
      eventCategory: 'page-click',
      eventAction: 'stop_details_change_map_size',
      eventLabel: `${!enlargedMap ? 'enlarge' : 'minimize'}`,
    });
    setTimeout(() => {
      mainMapInstance.resize();
    }, 400);
  }

  return (
    <div className='container'>
      <div className={`details-map ${enlargedMap ? 'big' : ''}`}>
        <div id='details-map'>
          <div className='map-buttons'>
            <div
              className={`fav-button ${isFavorite && 'active'}`}
              onClick={() => fabBtnClick(!isFavorite)}
            >
              {isFavorite && <HeartFilled color='#ec1c1c' width='40' />}
              {!isFavorite && <HeartFilled color='lightgrey' width='40' animated />}
            </div>
          </div>
          <div onClick={enlargeMap}>
            {!enlargedMap && <FullScreen width={25} color='#0271b5' className='map-button' />}
            {enlargedMap && <Minimize width={25} color='#0271b5' className='map-button' />}
          </div>
        </div>
      </div>
      <div className='content-wrapper'>
        <PageHeader title={!!stopCode ? stopName + ` (${stopCode})` : stopName} fixed />

        <InfoBlock>
          <span>{`Інформація оновиться за ${timerTime} секунд`}</span>
        </InfoBlock>
        {!enlargedMap && <div className='content-block'>{calculateTimeTableRender()}</div>}
      </div>
    </div>
  );
}
