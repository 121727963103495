/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import HomeMap from '../components/HomeMap';
import InfoBlock from '../components/InfoBlock';
import InputComponent from '../components/InputComponent';
import DraggableModal from '../components/DraggingModal';
import { useHistory } from 'react-router-dom';
import UseUserInactive from '../utils/UseUserInactive';
import UseTracking from '../utils/UseTracking';
import UseUserFeedback from '../utils/UseUserFeedback';
import UseGlobalMessages from '../utils/UseGlobalMessages';
import UseUUID from '../utils/UseUUID';
import UsePageActive from '../utils/UsePageActive';
import UseTheme from '../utils/UseTheme';
import LocateMe from '../components/LocateMe';
import MapWrapper from '../utils/MapWrapper';
import Tabs from '../components/Tabs';
import HeartFilled from '../icons/components/HeartFilled';
import Sun from '../icons/components/Sun';
import Moon from '../icons/components/Moon';
import GlobalMessage from '../components/GlobalMessage';

const mainMapInstance = MapWrapper();

export default function HomePage() {
  const history = useHistory();
  const [nearestBusStops, setNearestBusStops] = useState(null);
  const [closestStopNumber, setClosestStopNumber] = useState('');
  const [currentLocation, setCurrentLocation] = useState();
  const [nearestTransportData, setNearestTransportData] = useState();
  const [typeOfTransport, setTypeOfTransport] = useState('A');
  const textIos =
    'Додайте цей вебсайт як програму в телефоні, відкривши його в Safari, тоді "Поділитись", і "На початковий екран"';
  const textAndroid =
    'Додайте цей вебсайт як програму в телефоні, натиснувши в браузері "Меню", а тоді "На початковий екран чи інсталювати"';
  const visibleContentRef = useRef(null);
  const headerRef = useRef(null);
  const isCancelled = useRef(false);

  const TIMEOUT_TO_UPDTE_FROM_API = 5;
  const USER_INACTIVE_TIMEOUT = 180;
  const isPageActive = UsePageActive();
  const { messageToShow: feedbackMessages } = UseUserFeedback();
  const globalMessageToShow = UseGlobalMessages();
  UseUUID();
  let { currentTheme, switchTheme } = UseTheme();

  const { setTimerPaused } = UseUserInactive({
    callback: fetchNearestTransport,
    userTimeout: USER_INACTIVE_TIMEOUT,
    callbackTimeout: TIMEOUT_TO_UPDTE_FROM_API,
    pageName: 'HOME_PAGE',
  });

  useEffect(() => {
    if (isPWA() && !window.localStorage.getItem('PWA_tracking_fired') && window.ga) {
      window.ga('send', 'event', 'app_install', 'PWA_track_action');
      window.localStorage.setItem('PWA_tracking_fired', true);
    }

    return () => {
      isCancelled.current = true;
    };
  }, []);

  useEffect(() => {
    fetchNearestTransport();
  }, [currentLocation]);

  useEffect(() => {
    setTimerPaused(!isPageActive);
  }, [isPageActive]);

  function isPWA() {
    return window.matchMedia('(display-mode: standalone)').matches;
  }

  function fetchNearestTransport() {
    if (!currentLocation || !currentLocation.lon || !currentLocation.lat) {
      return;
    }
    fetch(
      `https://api.lad.lviv.ua/transport?longitude=${currentLocation.lon.toFixed(
        2,
      )}&latitude=${currentLocation.lat.toFixed(2)}`,
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (isCancelled.current) {
          return;
        }
        setNearestTransportData(data);
      });
  }

  function handleUserLocationSet(newLocation) {
    window.localStorage.setItem('lastUserLocation', JSON.stringify(newLocation));
    setCurrentLocation(newLocation);

    fetch(
      `https://api.lad.lviv.ua/closest?longitude=${newLocation.lon.toFixed(
        3,
      )}&latitude=${newLocation.lat.toFixed(3)}`,
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (isCancelled.current) {
          return;
        }
        setNearestBusStops(data);
        data[0] && setClosestStopNumber(data[0]['code']);
      });
  }

  var iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

  return (
    <div className='fixed-full-screen'>
      {globalMessageToShow &&
        globalMessageToShow.map(({ date, message }) => <GlobalMessage key={date} text={message} />)}
      {feedbackMessages &&
        feedbackMessages.map(({ message, date }, index) => (
          <GlobalMessage key={date + index} text={message} />
        ))}
      <InfoBlock hide={isPWA()} className='top-absolute-full-width'>
        <span>{iOS ? textIos : textAndroid}</span>
      </InfoBlock>
      <HomeMap
        setUserLocation={handleUserLocationSet}
        nearestBusStops={nearestBusStops}
        nearestTransportData={nearestTransportData}
        activeBusCode={closestStopNumber}
        mainMapInstance={mainMapInstance}
      />
      <DraggableModal
        isExpandable={false}
        visibleContentRef={visibleContentRef}
        headerRef={headerRef}
        elementOverModal={
          <div>
            <div className='info-btn mb-5' onClick={(stopId) => history.push(`/info`)}>
              i
            </div>
            <div
              className='fav-icon'
              onClick={() => {
                UseTracking({
                  eventCategory: 'page-click',
                  eventAction: 'go_to_favorites_page',
                  eventLabel: 'home page btn',
                });
                history.push('/favorites');
              }}
            >
              <HeartFilled color='#ec1c1c' animated />
            </div>
            <LocateMe
              updateUserLocation={mainMapInstance.updateUserLocation}
              className='modal-button'
            />
            <div
              className='theme-switcher'
              onClick={() => {
                UseTracking({
                  eventCategory: 'page-click',
                  eventAction: 'switch_theme',
                  eventLabel: currentTheme === 'light-theme' ? 'dark' : 'light',
                });
                switchTheme();
              }}
            >
              {currentTheme === 'dark-theme' && <Sun color='#FFFF' width='25px' />}
              {currentTheme === 'light-theme' && <Moon color='black' width='20px' />}
            </div>
          </div>
        }
      >
        <div ref={headerRef}>
          <Tabs activeIndex={0}>
            <span className='tabs__title mr-10'>Номер зупинки</span>
            <div>
              <InputComponent
                preFilledValue={closestStopNumber}
                className='pb-20'
                placeholderText='Код зупинки'
                callback={(stopId) => {
                  UseTracking({
                    eventCategory: 'page-click',
                    eventAction: 'search_by_bus_stop_ID',
                    eventLabel: stopId,
                  });
                  history.push(`/${stopId}`);
                }}
              />
            </div>
            <span className='tabs__title'>Номер маршруту</span>
            <div className='input-with-select pb-20'>
              <select
                className={'select minimal'}
                onChange={(e) => setTypeOfTransport(e.target.value)}
              >
                <option value='A'>Автобус</option>
                <option value='T'>Трамвай</option>
                <option value='Tp'>Тролейбус</option>
              </select>
              <InputComponent
                preFilledValue={''}
                placeholderText='Маршрут...'
                callback={(routeId) => {
                  UseTracking({
                    eventCategory: 'page-click',
                    eventAction: 'search_by_route_ID',
                    eventLabel: routeId,
                  });
                  routeId && history.push(`/route/${typeOfTransport}${routeId}`);
                }}
              />
            </div>
          </Tabs>
        </div>
      </DraggableModal>
    </div>
  );
}
