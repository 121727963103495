import React from "react";

export default function ({width='30px', color, className, ...props }) {
  return (
    <svg
      className={className}
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      fill={color}
      width={width}
    //   style={{"enableBackground":'new 0 0 512 512'}}
    >
      <g>
        <g>
          <g>
            <path
              d="M384,325.333c-23.564,0-42.667,19.102-42.667,42.667c0,23.564,19.102,42.667,42.667,42.667
				c23.564,0,42.667-19.102,42.667-42.667C426.637,344.448,407.552,325.363,384,325.333z M384,389.333
				c-11.782,0-21.333-9.551-21.333-21.333c0-11.782,9.551-21.333,21.333-21.333c11.782,0,21.333,9.551,21.333,21.333
				C405.333,379.782,395.782,389.333,384,389.333z"
            />
            <path
              d="M128,325.333c-23.564,0-42.667,19.102-42.667,42.667c0,23.564,19.103,42.667,42.667,42.667s42.667-19.102,42.667-42.667
				C170.637,344.448,151.552,325.363,128,325.333z M128,389.333c-11.782,0-21.333-9.551-21.333-21.333
				c0-11.782,9.551-21.333,21.333-21.333c11.782,0,21.333,9.551,21.333,21.333C149.333,379.782,139.782,389.333,128,389.333z"
            />
            <path
              d="M383.595,69.333H128c-26.315,0-42.667,16.352-42.667,42.667v160c0,24.725,17.941,42.667,42.667,42.667h256
				c14.656,0,42.667-16.619,42.667-42.667V112.405C426.643,88.627,407.373,69.357,383.595,69.333z M405.333,272
				c0,12.235-16.331,21.333-21.333,21.333H128c-13.152,0-21.333-8.181-21.333-21.333V112c0-14.752,6.581-21.333,21.333-21.333
				h255.595c12.001,0.012,21.727,9.738,21.739,21.739V272z"
            />
            <path
              d="M480,122.667h-10.667v-32c-0.047-41.218-33.449-74.62-74.667-74.667H117.333c-41.218,0.047-74.62,33.449-74.667,74.667
				v32H32c-17.673,0-32,14.327-32,32v64C0,230.449,9.551,240,21.333,240h21.333v170.667c0.028,10.659,4.073,20.916,11.328,28.725
				c-0.395,1.049-0.618,2.155-0.661,3.275v32c0,11.083,2.581,21.333,21.333,21.333H128c21.333,0,21.333-14.688,21.333-21.749
				v-20.917h213.333v20.917c0,7.061,0,21.749,21.333,21.749h53.333c21.333,0,21.333-14.688,21.333-21.749v-31.584
				c-0.043-1.12-0.266-2.226-0.661-3.275c7.255-7.809,11.3-18.066,11.328-28.725V240h21.333c11.782,0,21.333-9.551,21.333-21.333
				v-64C512,136.994,497.673,122.667,480,122.667z M42.667,218.667H21.333v-64C21.333,148.776,26.109,144,32,144h10.667V218.667z
				 M128,474.251v0.416H74.667v-22.848c3.476,0.959,7.061,1.468,10.667,1.515H128V474.251z M437.333,474.667H384v-21.333h42.667
				c3.606-0.047,7.19-0.556,10.667-1.515V474.667z M448,410.667c0,11.782-9.551,21.333-21.333,21.333H85.333
				C73.551,432,64,422.449,64,410.667v-320c0.035-29.441,23.893-53.298,53.333-53.333h277.333
				c29.441,0.035,53.298,23.893,53.333,53.333V410.667z M490.667,218.667h-21.333V144H480c5.891,0,10.667,4.776,10.667,10.667
				V218.667z"
            />
          </g>
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
}
