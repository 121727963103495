import React, { useEffect } from 'react';
import Feedback from '../components/Feedback';
import { Link } from 'react-router-dom';
import PageHeader from '../components/PageHeader';
import UseTracking from '../utils/UseTracking';

export default function () {
  useEffect(() =>
    UseTracking({
      eventCategory: 'page-visit',
      eventAction: 'not-found-page',
      eventLabel: window.location.pathname
    })
  );

  return (
    <div className='container'>
      <PageHeader
        overRideBackUrl='/'
        title='Сторінку не знайдено'
        subTitle=''
      />
      <h1 className='text-center'>Вибачте :(</h1>
      <img className='flex-center ' alt='not found' src='/panda.png'></img>
      <h2 className='text-center'>
        <Link to='/'>Назад на головну</Link>
      </h2>
      <Feedback feedbackLabel='Ви можете залишити відгук тут' />
    </div>
  );
}
