/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

export default function () {
  const [currentTheme, setCurrentTheme] = useState(true);

  useEffect(() => {
    setTheme();
  }, []);

  function setTheme(theme) {
    let newTheme = theme;
    const localStorageTheme = window.localStorage.getItem('user-theme');
    const docTheme = document.querySelector('body').className;

    if (!theme) {
      newTheme = localStorageTheme ? localStorageTheme : docTheme;
    }

    window.localStorage.setItem('user-theme', newTheme);
    document.querySelector('body').className = newTheme;
    setCurrentTheme(newTheme);
  }

  function switchTheme() {
    const currentTheme = document.querySelector('body').className;
    const newTheme =
      currentTheme === 'dark-theme' ? 'light-theme' : 'dark-theme';
    setTheme(newTheme);
    window.localStorage.setItem('user-enforsed-theme', newTheme);
    document.location.reload();
  }

  return { currentTheme, switchTheme };
}
