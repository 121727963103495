/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

let hidden, visibilityChange;
if (typeof document.hidden !== 'undefined') {
  // Opera 12.10 and Firefox 18 and later support
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

export default function () {
  const [isPageActive, setIsPageActive] = useState(true);

  useEffect(() => {
    document.addEventListener(visibilityChange, handleVisibilityChange, false);
  }, []);

  function handleVisibilityChange() {
    setIsPageActive(!document[hidden]);
  }

  return isPageActive;
}
