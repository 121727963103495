import React from 'react';
import PageHeader from '../components/PageHeader';
import { useParams } from 'react-router-dom';
import useInterval from '../utils/UseInterval';

const getClosetTime = (timeTable) => {
  if (!timeTable) {
    return;
  }
  const currentTime = new Date();
  const currentHours = currentTime.getHours();
  const currentMinutes = currentTime.getMinutes();

  const resHours = timeTable?.map((item, index) => {
    const timeMinutes = parseInt(item.split(':')[1], 10);
    const timeHours = parseInt(item, 10);
    const currentTime = `${currentHours}${currentMinutes}`;
    const time = `${timeHours}${timeMinutes}`;

    const timeDiff = parseInt(time) - parseInt(currentTime);

    if (timeDiff >= 0) {
      return { timeDiff, index, timeMinutes, item };
    }
  });

  resHours.sort((a, b) => {
    return a.timeDiff - b.timeDiff;
  });

  return resHours[0];
};

const SchedulePage = () => {
  let [staticRouteData, setStaticRouteData] = React.useState();
  let [stopsData, setStopsData] = React.useState();
  let [closestTime, setClosestTime] = React.useState();

  const { routeId } = useParams();
  const isCancelled = React.useRef(false);

  const startStopTimeTable = stopsData?.[0]?.departures;
  const endStopTimeTable = stopsData?.[1]?.departures;

  React.useLayoutEffect(() => {
    const start = staticRouteData?.stops[0][0];
    const end = staticRouteData?.stops[1][0];

    setStopsData([start, end]);
    calculateClosetDeparture(start?.departures, end?.departures);
  }, [staticRouteData]);

  React.useEffect(() => {
    fetch(`https://api.lad.lviv.ua/routes/static/${routeId}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (isCancelled.current) {
          return;
        }
        setStaticRouteData(data);
      });

    return () => {
      isCancelled.current = true;
    };
  }, []);

  const calculateClosetDeparture = (start, end) => {
    const closestStart = getClosetTime(start || startStopTimeTable);
    const closestEnd = getClosetTime(end || endStopTimeTable);
    setClosestTime({ closestStart, closestEnd });
  };

  useInterval(() => {
    calculateClosetDeparture();
  }, 30000);

  React.useEffect(() => {
    const activeTime = document.querySelectorAll('.active');
    if (!activeTime.length) {
      return;
    }

    const scrollElement =
      activeTime[1].offsetTop - activeTime[0].offsetTop > 0 ? activeTime[0] : activeTime[1];

    scrollElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }, [closestTime]);

  return (
    <div className='container'>
      <PageHeader
        title={`Розклад руху ${staticRouteData?.route_short_name}`}
        subTitle={staticRouteData?.route_long_name}
        className='sticky-header'
      />
      <div className='schedule-wrapper'>
        <div className='schedule-header-title'>
          <div className='schedule-title'>{stopsData?.[0]?.name}</div>
          <div className='schedule-title'>{stopsData?.[1]?.name}</div>
        </div>
        <div className='schedule-content'>
          <div className='schedule-content__item'>
            {startStopTimeTable?.map((time, index) => {
              const isActive = closestTime?.closestStart?.index === index;
              return (
                <div key={time + index} className={isActive ? 'active' : ''}>
                  {time.substr(0, 5)}
                </div>
              );
            })}
          </div>
          <div className='schedule-content__item'>
            {endStopTimeTable?.map((time, index) => {
              const isActive = closestTime?.closestEnd?.index === index;
              return (
                <div key={time + index} className={isActive ? 'active' : ''}>
                  {time.substr(0, 5)}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchedulePage;
