import React from 'react';
import BackButton from './BackButton';
import '../styles/detailedPage.scss';
import '../App.scss';

export default function ({ title, subTitle, overRideBackUrl, fixed, className }) {
  return (

      <div className={`header-block ${fixed ? 'header-block__fixed' : ''} ${className}`}>
        <BackButton overRideBackUrl={overRideBackUrl} />
        <div className='header-text'>
          <h3>{title}</h3>
          <div className='header-subtitle'>{subTitle}</div>
        </div>
      </div>
  );
}
