import React from 'react';

export default function ({ color, className, width, ...props }) {
  return (
    <svg
      className={className}
      version='1.1'
      id='Capa_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width={`${width}px`}
      height={`${width}px`}
      viewBox={'0 0 512 512'}
      fill={color}
      style={{ enableBackground: `new 0 0 512 512` }}
    >
      <g>
        <g>
          <path
            d='M506.132,477.792L383.034,354.693h78.869c11.068,0,20.039-8.971,20.039-20.039s-8.972-20.039-20.039-20.039h-87.17
			c-33.149,0-60.117,26.969-60.117,60.117v86.168c0,11.068,8.971,20.039,20.039,20.039s20.039-8.972,20.039-20.039v-77.867
			l123.097,123.097c3.914,3.913,9.043,5.869,14.171,5.869c5.128,0,10.257-1.957,14.171-5.869
			C513.957,498.305,513.957,485.617,506.132,477.792z'
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d='M137.269,314.615h-87.17c-11.068,0-20.039,8.971-20.039,20.039s8.971,20.039,20.039,20.039h78.869L5.869,477.792
			c-7.825,7.826-7.825,20.514,0,28.339c3.914,3.913,9.043,5.869,14.171,5.869c5.128,0,10.257-1.957,14.171-5.869l123.097-123.097
			v77.867c0,11.068,8.971,20.039,20.039,20.039s20.039-8.972,20.039-20.039v-86.168
			C197.386,341.584,170.417,314.615,137.269,314.615z'
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d='M506.132,5.87c-7.826-7.826-20.515-7.826-28.34,0L354.693,128.968V51.1c0-11.068-8.971-20.039-20.039-20.039
			s-20.039,8.971-20.039,20.039v86.168c0,33.149,26.969,60.117,60.117,60.117h87.17c11.068,0,20.039-8.971,20.039-20.039
			s-8.972-20.039-20.039-20.039h-78.869L506.132,34.209C513.957,26.383,513.957,13.695,506.132,5.87z'
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d='M177.347,30.059c-11.068,0-20.039,8.971-20.039,20.039v78.869L34.21,5.87c-7.826-7.826-20.515-7.826-28.34,0
			c-7.825,7.826-7.825,20.514,0,28.339l123.097,123.098H50.098c-11.068,0-20.039,8.971-20.039,20.039s8.971,20.039,20.039,20.039
			h87.17c33.149,0,60.117-26.969,60.117-60.117v-87.17C197.386,39.031,188.415,30.059,177.347,30.059z'
          />
        </g>
      </g>
    </svg>
  );
}
