import React, { useState, useEffect, useRef } from 'react';
import InfoBlock from '../components/InfoBlock';
import UseUUID from '../utils/UseUUID';

export default function ({ feedbackLabel }) {
  const feedbackLabelDefault =
    "Чим детальніше Ви опишете проблему - тим швидше ми її вирішимо.Можете написати свій телефон або пошту - зв'яжемося щоб розпитати детальніше.";
  const [feedbackText, setFeedbackText] = useState('');
  const [submited, setSubmited] = useState(false);
  const [userLocation, setUserLocation] = useState(
    JSON.parse(window.localStorage.getItem('lastUserLocation'))
  );
  const uuid = UseUUID();

  useEffect(() => {
    if (!userLocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation([
            position.coords.latitude,
            position.coords.longitude
          ]);
        },
        (err) => {
          console.log(err);
        },
        {}
      );
    } else {
      setUserLocation([userLocation.lat, userLocation.lon]);
    }
  }, []);

  function handleSubmit() {
    fetch('https://api.lad.lviv.ua/feedback', {
      method: 'POST',
      headers: {
        'access-control-allow-origin': '*',
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        message: feedbackText,
        uuid,
        location: userLocation
      })
    })
      .then((resp) => setSubmited(true))
      .catch((err) => {
        setSubmited(true);
      });
  }

  return (
    <div className='container'>
      <InfoBlock
        hide={false}
        className={`mt-25 ${!submited && 'visibility-h'}`}
      >
        <span>Дякуємо за відгук!</span>
      </InfoBlock>
      <div className={`secondaryText ${submited && 'visibility-h'}`}>
        {feedbackLabel || feedbackLabelDefault}
      </div>
      <textarea
        id='story'
        name='feedback'
        rows='15'
        cols='33'
        className={`text-area mt-15 ${submited && 'visibility-h'}`}
        onChange={(e) => setFeedbackText(e.target.value)}
        value={feedbackText}
        placeholder={'Напишіть щось...'}
      ></textarea>
      <button
        className={`btn btn-primary mt-15 ${submited && 'visibility-h'}`}
        onClick={handleSubmit}
        disabled={feedbackText.length < 3}
      >
        Надіслати
      </button>
    </div>
  );
}
