import { useState, useEffect, useRef } from 'react';
import useInterval from '../utils/UseInterval';
import UseTracking from '../utils/UseTracking';
import UseUUID from '../utils/UseUUID';

export default function ({
  callback,
  userTimeout,
  callbackTimeout,
  pageName = 'not_set'
}) {
  const CALLBACK_TIMEOUT = callbackTimeout || 5;
  const USER_INACTIVE_TIMEOUT = userTimeout || 10;
  const BACKLIST_TIMEOUT = 1000;

  const [timerPaused, setTimerPaused] = useState(false);
  const [userInactiveTime, setUserInactiveTime] = useState(0);
  const [timerTime, setTimerTime] = useState(CALLBACK_TIMEOUT);
  const [callBackCount, setCallBackCount] = useState(1);
  const [time, setTime] = useState(0);
  const isCancelled = useRef(false);
  const userId = UseUUID();

  useEffect(() => {
    activateActivityTracker();

    return () => {
      isCancelled.current = true;
      window.removeEventListener('mousemove', resetUserActivityTimeout);
      window.removeEventListener('scroll', resetUserActivityTimeout);
      window.removeEventListener('keydown', resetUserActivityTimeout);
      window.removeEventListener('resize', resetUserActivityTimeout);
    };
  }, []);

  function activateActivityTracker() {
    window.addEventListener('mousemove', resetUserActivityTimeout);
    window.addEventListener('scroll', resetUserActivityTimeout);
    window.addEventListener('keydown', resetUserActivityTimeout);
    window.addEventListener('resize', resetUserActivityTimeout);
  }

  function resetUserActivityTimeout() {
    setUserInactiveTime(0);
  }

  function inactiveUserAction() {
    setTimerPaused(true);
    window.confirm('Ви ще тут?');

    setTimerPaused(false);
    resetUserActivityTimeout();
  }

  useInterval(
    async () => {
      if (isCancelled.current) {
        return;
      }
      setTime(time + 1);
      setUserInactiveTime(userInactiveTime + 1);
      if (userInactiveTime === BACKLIST_TIMEOUT) {
        UseTracking({
          eventCategory: 'user-timer',
          eventAction: 'blacklist',
          eventLabel: userId
        });
      }
      if (userInactiveTime >= USER_INACTIVE_TIMEOUT - 1) {
        inactiveUserAction();
      }
      if (timerTime === 0) {
        setTimerPaused(true);
        try {
          await callback(time);
          setCallBackCount(callBackCount + 1);
          UseTracking({
            eventCategory: 'user-timer',
            eventAction: 'timeout',
            eventLabel: `${pageName}_${callBackCount}`
          });
        } catch (err) {
          console.log(`Callback failed with: ${err}`);
        }
        setTimerTime(CALLBACK_TIMEOUT);
        setTimerPaused(false);
        return;
      }
      setTimerTime(timerTime - 1);
    },
    timerPaused ? null : 1000
  );

  return { timerTime, setTimerPaused };
}
