import React from "react";

export default function ({
  width = "30px",
  color,
  className,
  animated,
  ...props
}) {
  return (
    <svg
      className={className}
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
      width={width}
      height={width}
      viewBox={`0 0 200 200`}
    >
      <g>
        <g transform={`translate(100 100)`}>
          <path
            transform={`translate(-50 -40)`}
            fill={color}
            d="M92.71,7.27L92.71,7.27c-9.71-9.69-25.46-9.69-35.18,0L50,14.79l-7.54-7.52C32.75-2.42,17-2.42,7.29,7.27v0 c-9.71,9.69-9.71,25.41,0,35.1L50,85l42.71-42.63C102.43,32.68,102.43,16.96,92.71,7.27z"
          ></path>
          {animated && (
            <animateTransform
              attributeName="transform"
              type="scale"
              values="1; 1.5; 1.25; 1.5; 1.5; 1;"
              dur="1s"
              repeatCount="3"
              additive="sum"
            />
          )}
        </g>
      </g>
    </svg>
  );
}
