import React from 'react';
import BusIcon from '../icons/components/BusIcon';
import TramIcon from '../icons/components/TramIcon';
import TrolleyIcon from '../icons/components/TrolleyIcon';
import '../styles/routeDetails.scss';

export default function ({
  allStopsData,
  routeId,
  nodeClickCallBack,
  visibleContentRef,
  headerRef,
  smallSubtitle,
  ...props
}) {
  let routeName = allStopsData.route_long_name;
  let allStops = allStopsData.stops;
  let vehicleType = allStopsData.vehicle_type || allStopsData.type || 'bus';

  function handleNodeClick(code) {
    nodeClickCallBack(code);
  }

  function renderGraphTree({
    name,
    index,
    back,
    code,
    shouldbeVisibleInModal,
    transfers
  }) {
    return (
      <div
        className='details-content'
        key={name + index}
        {...(shouldbeVisibleInModal && { ref: visibleContentRef })}
      >
        <div className='route-graph'>
          <div className='route-node' onClick={() => handleNodeClick(code)}>
            <div className='route-node-left'>
              <span className={`route-node-indicator ${back && 'back'}`}></span>
              <span className='route-node-name'>{name}</span>
              {/* <div className='transfer-wrapper'>
                {transfers.map((transfer) => (
                  <span
                    className='route-node-transfer'
                    style={{
                      backgroundColor: transfer.color,
                      color: isLightColor(transfer.color) ? 'black' : 'white'
                    }}
                  >
                    {transfer.route}
                  </span>
                ))}
              </div> */}
              <span className={`route-node-connector ${back && 'back'}`}></span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className='details-header' ref={headerRef}>
        {vehicleType === 'bus' && (
          <BusIcon width='30px' color='#60b929' className='mr-1' />
        )}
        {vehicleType === 'tram' && (
          <TramIcon width='35px' color='#60b929' className='mr-1' />
        )}
        {vehicleType === 'trol' && (
          <TrolleyIcon width='30px' color='#60b929' className='mr-1' />
        )}

        <div className='details-title mr-2'>{routeId}</div>
        <div className='details-subtitle-block'>
          <div className='details-subtitle'>{routeName}</div>
          {smallSubtitle && (
            <div className='details-subtitle-small'>{smallSubtitle}</div>
          )}
        </div>
      </div>
      <div className='graphs-content'>
        <div>
          {allStops &&
            allStops[0].map(({ name, code, transfers }, index) =>
              renderGraphTree({
                name,
                index,
                code,
                transfers,
                shouldbeVisibleInModal: index === 0
              })
            )}
        </div>
        <div>
          {allStops &&
            allStops[1].map(({ name, code, transfers }, index) =>
              renderGraphTree({ name, index, back: true, code, transfers })
            )}
        </div>
      </div>
    </div>
  );
}
