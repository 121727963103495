import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import HomePage from './views/HomePage';
import RouteDetailsPage from './views/RouteDetailsPage';
import StopDetailsRedesigned from './views/StopDetailsRedesigned';
import VehicleDetailsPage from './views/VehicleDetailsPage';
import InfoPage from './views/InfoPage';
import FavoritePage from './views/FavoritePage';
import NotFoundPage from './views/NotFoundPage';
import SchedulePage from './views/SchedulePage';
import UseTheme from './utils/UseTheme';

export default function BasicExample() {
  UseTheme();
  return (
    <Router>
      <Switch>
        <Route exact path='/'>
          <HomePage />
        </Route>
        <Route path='/stops/:stopId(\d+)'>
          <StopDetailsRedesigned />
        </Route>
        <Route path='/route/:routeId/schedule'>
          <SchedulePage />
        </Route>
        <Route path='/route/:routeId'>
          <RouteDetailsPage />
        </Route>
        <Route path='/vehicle/:vehicleId'>
          <VehicleDetailsPage />
        </Route>
        <Route path='/info'>
          <InfoPage />
        </Route>
        <Route path='/favorites'>
          <FavoritePage />
        </Route>
        <Route path='/not-found'>
          <NotFoundPage />
        </Route>
        <Redirect from='/:stopId(\d+)' to='/stops/:stopId' />
        <Route path='*' exact={true} component={NotFoundPage} />
      </Switch>
    </Router>
  );
}
