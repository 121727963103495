import React, { useState } from 'react';
import Compass from '../icons/components/Compass';
import UseTracking from '../utils/UseTracking';
import UseTheme from '../utils/UseTheme';

export default function ({ locateUserRef, updateUserLocation, className }) {
  let [isLoading, setIsLoading] = useState(false);
  let { currentTheme } = UseTheme();

  function _getCurrentUserLocation(force = false) {
    setIsLoading(true);
    UseTracking({
      eventCategory: 'page-click',
      eventAction: 'locate_me_button',
      eventLabel: 'click_locate_me',
    });
    var options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

    function error(err) {
      setIsLoading(false);
      console.warn(`ERROR(${err.code}): ${err.message}`);
    }

    let success = (position) => {
      let location = {};
      location.lat = position.coords.latitude;
      location.lon = position.coords.longitude;
      updateUserLocation(location, force);
      setIsLoading(false);
    };
    let locationFromLocalStorage = JSON.parse(window.localStorage.getItem('lastUserLocation'));

    if (locationFromLocalStorage && !force) {
      updateUserLocation(locationFromLocalStorage);
    } else {
      navigator.geolocation.getCurrentPosition(success, error, options);
    }
  }

  return (
    <div
      className={`locate-user ${className ? className : ''}`}
      ref={locateUserRef}
      onClick={() => _getCurrentUserLocation(true)}
    >
      <Compass width='35' isLoading={isLoading} isDarkMode={currentTheme === 'dark-theme'} />
    </div>
  );
}
