import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import UseTracking from '../utils/UseTracking';

let busMarkers = [];
let transportMarkers = [];
let busMarkerIcons = [];

export default function HomeMap({
  nearestBusStops,
  activeBusCode,
  locateUserRef,
  mainMapInstance,
  nearestTransportData,
  ...props
}) {
  let history = useHistory();

  useEffect(() => {
    busMarkers = [];
    busMarkerIcons = [];

    mainMapInstance.init({
      container: 'home-map',
      showCurrentLocation: true,
      onMoveCallBack: props.setUserLocation
    });
  }, []);

  useEffect(() => {
    if (!nearestBusStops || !nearestBusStops[0]) {
      return;
    }
    renderBusStops(nearestBusStops, history);
  }, [nearestBusStops]);

  useEffect(() => {
    if (!nearestTransportData || !nearestTransportData[0]) {
      return;
    }
    renderTransport(nearestTransportData, history);
  }, [nearestTransportData]);

  useEffect(() => {
    hightLightClosestMarker();
  }, [activeBusCode]);

  function hightLightClosestMarker() {
    let activeMarker = activeBusCode;
    let activeElement = busMarkerIcons.filter((markerElementItem) =>
      markerElementItem
        .getElement()
        .classList.contains(`bus-stop-id-${activeMarker}`)
    )[0];

    if (!activeElement) {
      return;
    }
    busMarkerIcons.forEach((marker) => {
      marker
        .getElement()
        .querySelector('.marker-inner')
        .classList.remove('active');
    });

    activeElement
      .getElement()
      .querySelector('.marker-inner')
      .classList.add('active');
  }

  function renderBusStops(busStops, history) {
    busStops.forEach((busStop, index) => {
      if (
        busMarkers.filter((alreadyDrawnbusStop) => {
          return alreadyDrawnbusStop.code === busStop.code;
        }).length
      ) {
        return;
      }

      busMarkers.push(busStop);
      busMarkerIcons.push(
        mainMapInstance.createMarker({
          location: {
            lon: busStop.longitude,
            lat: busStop.latitude
          },
          iconSize: [38, 95],
          html: `<span class="marker-inner"></span>`,
          className: `bus-stop-marker bus-stop-id-${busStop.code}`,
          callBack: () => {
            UseTracking({
              eventCategory: 'page-click',
              eventAction: 'home_map_click',
              eventLabel: 'stop_marker_click'
            });
            history.push(`/stops/${busStop.code}`);
          }
        })
      );
    });
  }

  function renderTransport(nearestTransportData, history) {
    clearAllTransport();
    nearestTransportData.forEach((transportData, index) => {
      let bearing = transportData.bearing - 90;
      let color = transportData.color;
      let vehicleId = transportData.id;

      transportMarkers.push(
        mainMapInstance.createMarker({
          location: transportData.location,
          iconSize: [25, 25],
          html: `<div class="marker-bus-inner" style="transform: rotate(${bearing}deg); background-color: ${color}">&#10148;</div><span class="label">${transportData.route}</span></div>`,
          className: `bus-marker bus-marker-id-`,
          callBack: () => {
            UseTracking({
              eventCategory: 'page-click',
              eventAction: 'home_map_click',
              eventLabel: 'vehicle_marker_click'
            });
            history.push(`/vehicle/${vehicleId}`);
          }
        })
      );
    });
  }

  function clearAllTransport() {
    transportMarkers.forEach((marker) => marker.remove());
    transportMarkers = [];
  }

  return (
    <div>
      <div id='home-map'></div>
    </div>
  );
}
