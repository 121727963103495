/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

export default function useInterval() {
  const [uuid, setUuid] = useState(window.localStorage.getItem("lad-id"));

  useEffect(() => {
    setUUID();
  }, []);

  function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  }

  function setUUID() {
    if (!uuid) {
      window.localStorage.setItem("lad-id", uuidv4());
    }
  }
  return uuid;
}
