import React, { useState, useEffect } from "react";
import "../styles/lineCard.scss";
import { useHistory } from "react-router-dom";
import LineCard from "../components/LineCard";

export default function ({ timetable, ...props }) {
  const history = useHistory();
  const [routesGroups, setRoutesGroups] = useState([]);
  const [expandedGroups, setExpandedGroups] = useState({});

  function onClickCallback(route, vehicleId) {
    history.push(`/vehicle/${vehicleId}`);
  }

  function onStackClick(value) {
    let expanded = { ...expandedGroups };
    expanded[value] = !expanded[value];
    setExpandedGroups(expanded);
  }

  function calculateBackCardStyle(index, value) {
    let style;
    if (expandedGroups[value]) {
      style = {
        transform: `translate(40px, 0px)`,
        opacity: 0.85,
        width: "calc(100% - 40px)",
      };
    } else {
      style = {
        transform: `translate(${0}px, ${(index + 1) * 10}px) scaleX(${
          1 - (index + 1) * 0.05
        })`,
        opacity: `${1 - (index + 5) * 0.1}`,
      };
    }
    return style;
  }

  useEffect(() => {
    setRoutesGroups(
      timetable.reduce((acc, vehicleItem) => {
        if (!acc[vehicleItem.route] || !acc[vehicleItem.route].length) {
          acc[vehicleItem.route] = [];
        }
        acc[vehicleItem.route].push(vehicleItem);
        return acc;
      }, {})
    );
  }, [timetable]);

  return Object.keys(routesGroups).map((value, index) => {
    const routeData = routesGroups[value];
    const isExpanded = expandedGroups[value];
    if (routeData.length > 1) {
      return (
        <div
          className="card-stack mt-25"
          onClick={onStackClick.bind(null, value)}
          key={value}
        >
          <LineCard
            className="mb-15 mt-15"
            data={routeData[0]}
            isFirst={true}
            key={routeData[0].vehicle_id + index}
            onClickCallback={onClickCallback}
            isInStack
          />
          {routeData.slice(1, routeData.length).map((item, index) => {
            return (
              <div
                className={`background-card ${!isExpanded && "p-absolute"}`}
                key={item.vehicle_id + index}
                style={calculateBackCardStyle(index, value)}
              >
                <LineCard
                  className={`${
                    index > 1 && !isExpanded ? "visibility-h" : "mb-1"
                  }`}
                  data={item}
                  hideTimeLeft={!isExpanded}
                  isFirst={false}
                  onClickCallback={onClickCallback}
                />
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <LineCard
          className="mt-25"
          data={routesGroups[value][0]}
          isFirst={true}
          key={routesGroups[value][0].vehicle_id + index}
          onClickCallback={onClickCallback}
        />
      );
    }
  });
}
