import React from 'react';

export default function ({
  isLoading,
  color,
  className,
  width,
  isDarkMode,
  ...props
}) {
  return (
    <svg
      className={className}
      version='1.1'
      id='Capa_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width={`${width}px`}
      height={`${width}px`}
      viewBox={'0 0 512 512'}
      style={{ enableBackground: `new 0 0 512 512` }}
    >
      <circle
        style={{ fill: isDarkMode ? 'black' : '#90CAF9' }}
        cx='256'
        cy='256'
        r='240'
      />
      <path
        style={{ fill: isDarkMode ? '#ECEFF1' : '#ECEFF1' }}
        d='M364.224,147.776L241.12,270.88l-88.8-35.36c-5.15-2.125-8.431-7.233-8.224-12.8
	c0.003-5.612,3.661-10.568,9.024-12.224l197.28-65.92C355.247,143.045,360.543,144.271,364.224,147.776z'
      />
      {isLoading && (
        <animateTransform
          attributeName='transform'
          attributeType='XML'
          type='rotate'
          from='0 0 0'
          to='360 0 0'
          dur='2s'
          additive='sum'
          accumulate='sum'
          repeatCount='indefinite'
        />
      )}
      <path
        style={{ fill: '#FAFAFA' }}
        d='M367.424,161.6l-65.952,197.28c-1.656,5.363-6.612,9.021-12.224,9.024h-0.256
	c-5.461,0.077-10.417-3.181-12.512-8.224l-35.36-88.8l123.104-123.104C367.717,151.464,368.941,156.753,367.424,161.6z'
      />
      <path
        style={{ fill: isDarkMode ? '#FAFAFA' : 'inherit' }}
        d='M256,512C114.615,512,0,397.385,0,256S114.615,0,256,0s256,114.615,256,256C511.841,397.319,397.319,511.841,256,512z
	 M256,32C132.288,32,32,132.288,32,256s100.288,224,224,224s224-100.288,224-224C479.859,132.347,379.653,32.141,256,32z'
      />
      <path
        style={{ fill: isDarkMode ? '#FAFAFA' : 'inherit' }}
        d='M496,272h-64c-8.837,0-16-7.163-16-16c0-8.837,7.163-16,16-16h64c8.837,0,16,7.163,16,16C512,264.837,504.837,272,496,272z'
      />
      <path
        style={{ fill: isDarkMode ? '#FAFAFA' : 'inherit' }}
        d='M80,272H16c-8.837,0-16-7.163-16-16c0-8.837,7.163-16,16-16h64c8.837,0,16,7.163,16,16C96,264.837,88.837,272,80,272z'
      />
      <path
        style={{ fill: isDarkMode ? '#FAFAFA' : 'inherit' }}
        d='M256,96c-8.837,0-16-7.163-16-16V16c0-8.837,7.163-16,16-16c8.837,0,16,7.163,16,16v64C272,88.837,264.837,96,256,96z'
      />
      <path
        style={{ fill: isDarkMode ? '#FAFAFA' : 'inherit' }}
        d='M256,512c-8.837,0-16-7.163-16-16v-64c0-8.837,7.163-16,16-16c8.837,0,16,7.163,16,16v64C272,504.837,264.837,512,256,512z'
      />
      <path
        d='M279.584,406.784c-6.537-0.002-12.416-3.98-14.848-10.048l-42.72-106.752L115.2,247.264
	c-8.201-3.291-12.181-12.607-8.89-20.808c1.746-4.35,5.311-7.716,9.754-9.208l237.568-79.232
	c8.378-2.809,17.447,1.706,20.256,10.084c1.106,3.3,1.106,6.871,0,10.172l-79.104,237.6c-2.132,6.365-8.01,10.723-14.72,10.912
	H279.584z M167.584,233.76l72.576,29.024c4.062,1.634,7.277,4.86,8.896,8.928l29.024,72.576l55.264-165.792L167.584,233.76z'
      />
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
}
