import React from 'react';

export default function ({ color, className, width, ...props }) {
  return (
    <svg
      className={className}
      version='1.1'
      id='Capa_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width={`${width}px`}
      height={`${width}px`}
      viewBox={'0 0 32.529 32.529'}
      fill={color}
      style={{ enableBackground: `new 0 0 512 512` }}
    >
      <g>
        <path
          d='M18.72,13.811c-0.846-0.846-0.846-2.217,0-3.063l6.168-6.168l-3.131-0.254c-0.533-0.045-1.006-0.277-1.357-0.628
		c-0.434-0.434-0.682-1.048-0.627-1.707c0.1-1.192,1.145-2.08,2.334-1.983l7.799,0.635c1.059,0.088,1.896,0.927,1.982,1.983
		l0.635,7.797c0.098,1.191-0.791,2.236-1.982,2.334c-1.19,0.097-2.235-0.791-2.334-1.983l-0.254-3.129l-6.168,6.167
		C20.937,14.659,19.565,14.659,18.72,13.811z M10.747,18.719l-6.166,6.167l-0.256-3.13c-0.098-1.192-1.143-2.08-2.334-1.982
		c-1.193,0.099-2.08,1.144-1.984,2.333l0.635,7.8c0.086,1.056,0.927,1.895,1.984,1.98l7.797,0.635
		c1.191,0.099,2.236-0.791,2.334-1.981c0.055-0.659-0.193-1.273-0.627-1.708c-0.352-0.353-0.822-0.582-1.355-0.627L7.644,27.95
		l6.166-6.167c0.849-0.848,0.849-2.219,0.002-3.063C12.964,17.872,11.593,17.872,10.747,18.719z M30.538,19.772
		c-0.66-0.055-1.273,0.193-1.707,0.627c-0.353,0.353-0.584,0.823-0.627,1.356l-0.256,3.13l-6.166-6.167
		c-0.849-0.846-2.22-0.846-3.062,0c-0.849,0.846-0.849,2.218,0,3.063l6.166,6.167l-3.132,0.254c-1.19,0.098-2.077,1.144-1.981,2.335
		c0.099,1.19,1.144,2.08,2.337,1.982l7.795-0.636c1.057-0.086,1.896-0.925,1.983-1.981l0.633-7.799
		C32.619,20.915,31.731,19.869,30.538,19.772z M7.644,4.58l3.131-0.254c1.191-0.098,2.078-1.143,1.982-2.336
		c-0.099-1.192-1.144-2.079-2.335-1.983L2.623,0.643C1.568,0.729,0.727,1.568,0.642,2.626l-0.635,7.797
		c-0.097,1.192,0.79,2.236,1.983,2.334c0.657,0.054,1.272-0.193,1.707-0.628c0.353-0.351,0.584-0.821,0.627-1.355L4.58,7.643
		l6.168,6.167c0.845,0.847,2.218,0.847,3.062,0c0.848-0.846,0.848-2.217,0.002-3.063L7.644,4.58z'
        />
      </g>
    </svg>
  );
}
