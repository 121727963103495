import React from 'react';

export default function ({ color, className, width, ...props }) {
  return (
    <svg
      className={className}
      version='1.1'
      id='Capa_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width={`${width}px`}
      height={`${width}px`}
      viewBox={'0 0 512 512'}
      fill={color}
      style={{ enableBackground: `new 0 0 512 512` }}
    >
      <g>
        <g id='XMLID_17_'>
          <g>
            <path
              style={{ fill: '#ffffff3d' }}
              d='M197.31,218.39c0,11.62,9.46,21.07,21.08,21.07c6.04,0,11.48-2.57,15.33-6.66h70.76
				c3.87,0,7.51-1.51,10.25-4.25c2.74-2.74,4.25-6.38,4.25-10.25c0-8-6.51-14.5-14.5-14.5h-70.92c-0.31-0.33-0.64-0.63-0.97-0.94
				v-70.95c0-3.87-1.51-7.51-4.25-10.25c-2.74-2.74-6.38-4.25-10.25-4.25c-8,0-14.5,6.5-14.5,14.5v71.5
				C199.72,207.23,197.31,212.53,197.31,218.39z M222.89,44.51c28.37,0.72,55.07,8.28,78.52,21.07l-14.89,25.8l7.79,4.5L309.2,70.1
				c23.37,14.37,43.11,34.1,57.48,57.48l-25.78,14.88l4.5,7.79l25.79-14.89c12.8,23.45,20.35,50.16,21.07,78.53h-29.81v9h29.81
				c-0.72,28.37-8.27,55.07-21.07,78.52l-25.79-14.89l-4.5,7.79l25.77,14.89c-14.36,23.37-34.1,43.11-57.47,57.47l-14.89-25.77
				l-7.79,4.5l14.89,25.79c-23.45,12.8-50.15,20.35-78.52,21.07v-29.81h-9v29.81c-28.37-0.72-55.08-8.27-78.53-21.07l14.89-25.79
				l-7.79-4.5l-14.88,25.77c-23.38-14.36-43.11-34.1-57.48-57.47l25.78-14.89l-4.5-7.79l-25.8,14.89
				c-12.79-23.45-20.35-50.15-21.07-78.52h29.82v-9H44.51c0.72-28.37,8.28-55.08,21.07-78.53l25.8,14.89l4.5-7.79L70.1,127.58
				c14.37-23.38,34.1-43.11,57.48-57.48l14.88,25.78l7.79-4.5l-14.89-25.8c23.45-12.79,50.15-20.35,78.53-21.07v29.82h9V44.51z'
            />
            <path
              style={{ fill: '#4489D3' }}
              d='M218.39,9c115.45,0,209.39,93.93,209.39,209.39c0,115.45-93.94,209.39-209.39,209.39
				C102.93,427.78,9,333.84,9,218.39C9,102.93,102.93,9,218.39,9z M218.39,401.32c100.87,0,182.93-82.06,182.93-182.93
				S319.26,35.45,218.39,35.45S35.45,117.52,35.45,218.39S117.52,401.32,218.39,401.32z'
            />
            <path
              d='M127.58,366.67l14.88-25.77l7.79,4.5l-14.89,25.79c23.45,12.8,50.16,20.35,78.53,21.07v-29.81h9v29.81
				c28.37-0.72,55.07-8.27,78.52-21.07l-14.89-25.79l7.79-4.5l14.89,25.77c23.37-14.36,43.11-34.1,57.47-57.47l-25.77-14.89
				l4.5-7.79l25.79,14.89c12.8-23.45,20.35-50.15,21.07-78.52h-29.81v-9h29.81c-0.72-28.37-8.27-55.08-21.07-78.53l-25.79,14.89
				l-4.5-7.79l25.78-14.88c-14.37-23.38-34.11-43.11-57.48-57.48l-14.89,25.78l-7.79-4.5l14.89-25.8
				c-23.45-12.79-50.15-20.35-78.52-21.07v29.82h-9V44.51c-28.38,0.72-55.08,8.28-78.53,21.07l14.89,25.8l-7.79,4.5L127.58,70.1
				c-23.38,14.37-43.11,34.1-57.48,57.48l25.78,14.88l-4.5,7.79l-25.8-14.89c-12.79,23.45-20.35,50.16-21.07,78.53h29.82v9H44.51
				c0.72,28.37,8.28,55.07,21.07,78.52l25.8-14.89l4.5,7.79L70.1,309.2C84.47,332.57,104.2,352.31,127.58,366.67z M218.39,35.45
				c100.87,0,182.93,82.07,182.93,182.94s-82.06,182.93-182.93,182.93S35.45,319.26,35.45,218.39S117.52,35.45,218.39,35.45z'
            />
            <path
              style={{ fill: '#4489D3' }}
              d='M309.98,218.3c0,1.47-0.58,2.85-1.62,3.89c-1.03,1.03-2.42,1.61-3.88,1.61h-65.75
				c0.46-1.73,0.73-3.54,0.73-5.41c0-1.94-0.28-3.81-0.78-5.59h65.8C307.51,212.8,309.98,215.27,309.98,218.3z'
            />
            <path
              style={{ fill: '#F97946' }}
              d='M230.46,218.39c0,6.65-5.42,12.07-12.07,12.07c-6.66,0-12.08-5.42-12.08-12.07
				c0-6.66,5.42-12.08,12.08-12.08C225.04,206.31,230.46,211.73,230.46,218.39z'
            />
            <path
              d='M218.39,230.46c6.65,0,12.07-5.42,12.07-12.07c0-6.66-5.42-12.08-12.07-12.08c-6.66,0-12.08,5.42-12.08,12.08
				C206.31,225.04,211.73,230.46,218.39,230.46z M212.59,198.14c1.84-0.53,3.78-0.83,5.8-0.83c1.79,0,3.53,0.25,5.2,0.68v-66.08
				c0-1.47-0.58-2.85-1.62-3.89c-1.04-1.04-2.42-1.61-3.88-1.61c-3.04,0-5.5,2.46-5.5,5.5V198.14z M318.98,218.3
				c0,3.87-1.51,7.51-4.25,10.25c-2.74,2.74-6.38,4.25-10.25,4.25h-70.76c-3.85,4.09-9.29,6.66-15.33,6.66
				c-11.62,0-21.08-9.45-21.08-21.07c0-5.86,2.41-11.16,6.28-14.98v-71.5c0-8,6.5-14.5,14.5-14.5c3.87,0,7.51,1.51,10.25,4.25
				c2.74,2.74,4.25,6.38,4.25,10.25v70.95c0.33,0.31,0.66,0.61,0.97,0.94h70.92C312.47,203.8,318.98,210.3,318.98,218.3z
				 M309.98,218.3c0-3.03-2.47-5.5-5.5-5.5h-65.8c0.5,1.78,0.78,3.65,0.78,5.59c0,1.87-0.27,3.68-0.73,5.41h65.75
				c1.46,0,2.85-0.58,3.88-1.61C309.4,221.15,309.98,219.77,309.98,218.3z'
            />
            <path
              style={{ fill: '#4489D3' }}
              d='M218.09,126.41c1.46,0,2.84,0.57,3.88,1.61c1.04,1.04,1.62,2.42,1.62,3.89v66.08
				c-1.67-0.43-3.41-0.68-5.2-0.68c-2.02,0-3.96,0.3-5.8,0.83v-66.23C212.59,128.87,215.05,126.41,218.09,126.41z'
            />
            <path
              d='M9,218.39c0,115.45,93.93,209.39,209.39,209.39c115.45,0,209.39-93.94,209.39-209.39C427.78,102.93,333.84,9,218.39,9
				C102.93,9,9,102.93,9,218.39z M372.81,63.96c41.25,41.25,63.97,96.09,63.97,154.43c0,58.33-22.72,113.17-63.97,154.42
				s-96.09,63.97-154.42,63.97c-58.34,0-113.18-22.72-154.43-63.97S0,276.72,0,218.39c0-58.34,22.71-113.18,63.96-154.43
				S160.05,0,218.39,0C276.72,0,331.56,22.71,372.81,63.96z'
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
