import React, { useState } from "react";
import "../styles/infoBlock.scss";

export default function ({ className, hide, ...props }) {
  const [closed, setClosed] = useState(false);

  function hadleCloseEvent() {
    setClosed(true);
  }

  return (
    <div>
      {!hide && !closed && (
        <div className={`info-block ${className}`}>
          {props.children}
          <span className="info-block-close" onClick={hadleCloseEvent}>
            &#10005;
          </span>
        </div>
      )}
    </div>
  );
}
