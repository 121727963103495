import React, { useState, useEffect, useRef } from 'react';
import PageHeader from '../components/PageHeader';
import Feedback from '../components/Feedback';
import MessageBubble from '../components/MessageBubble';
import UseUserFeedback from '../utils/UseUserFeedback';
import SendIcon from '../icons/components/SendIcon';
import '../styles/infoPage.scss';
import UseUUID from '../utils/UseUUID';

export default function () {
  const { allMessages } = UseUserFeedback();
  const [feedbackText, setFeedbackText] = useState('');
  const [displayedText, setDisplayedText] = useState(allMessages);
  const uuid = UseUUID();
  const messagesEndRef = useRef(null);
  const [userLocation, setUserLocation] = useState(
    JSON.parse(window.localStorage.getItem('lastUserLocation'))
  );

  useEffect(() => {
    setDisplayedText(allMessages);
  }, [allMessages]);

  useEffect(() => {
    scrollToBottom();
  }, [messagesEndRef && messagesEndRef.current]);

  function renderMessags() {
    return (
      <div className="messages-container mt-25">
        {displayedText &&
          displayedText.map(({ message, date, is_response }) => (
            <MessageBubble
              key={date}
              message={message}
              className={`mt-10 ${is_response ? 'response' : ''}`}
            />
          ))}
        <div ref={messagesEndRef} />
      </div>
    );
  }

  useEffect(() => {
    if (!userLocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation([
            position.coords.latitude,
            position.coords.longitude
          ]);
        },
        (err) => {
          console.log(err);
        },
        {}
      );
    } else {
      setUserLocation([userLocation.lat, userLocation.lon]);
    }
  }, []);

  function handleSubmit() {
    fetch('https://api.lad.lviv.ua/feedback', {
      method: 'POST',
      headers: {
        'access-control-allow-origin': '*',
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        message: feedbackText,
        uuid,
        location: userLocation
      })
    }).then((resp) => {
      let messages = [...displayedText];
      messages.push({ message: feedbackText, date: new Date().toISOString() });
      setDisplayedText(messages);
      setFeedbackText('');
      scrollToBottom();
    });
  }

  function sendMessage() {
    handleSubmit();
  }

  const scrollToBottom = () => {
    if (messagesEndRef && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  function renderFeedBackForm() {
    if (!allMessages || !allMessages.length) {
      return <Feedback />;
    } else if (allMessages && allMessages.length) {
      return renderMessags();
    }
  }

  function renderInputField() {
    if (allMessages && allMessages.length) {
      return (
        <div className="messanger-input">
          <input
            placeholder="Введіть повідомлення"
            onChange={(e) => setFeedbackText(e.target.value)}
            value={feedbackText}
          />
          <div onClick={sendMessage} className="send-button">
            <SendIcon width="28px" height="28px" />
          </div>
        </div>
      );
    }
  }

  return (
    <div className="container">
      <PageHeader title="Напишіть нам" subTitle="ми все читаємо, чесно! :)" />
      {renderFeedBackForm()}
      {renderInputField()}
    </div>
  );
}
