import React from "react";

export default function ({ width = "30px", color, className, ...props }) {
  return (
    <svg
      className={className}
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      fill={color}
      width={width}
      //   style={{"enableBackground":'new 0 0 512 512'}}
    >
      <g>
        <g>
          <path
            d="M171.499,346.031h-19.833c-16.542,0-30,13.458-30,30s13.458,30,30,30h19.833c16.542,0,30-13.458,30-30
			S188.041,346.031,171.499,346.031z M171.499,386.031h-19.833c-5.514,0-10-4.486-10-10c0-5.514,4.486-10,10-10h19.833
			c5.514,0,10,4.486,10,10C181.499,381.545,177.013,386.031,171.499,386.031z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M360.333,346.031h-19.834c-16.542,0-30,13.458-30,30s13.458,30,30,30h19.833c16.542,0,30-13.458,30-30
			S376.875,346.031,360.333,346.031z M360.333,386.031h-19.834c-5.514,0-10-4.486-10-10c0-5.514,4.486-10,10-10h19.833
			c5.514,0,10,4.486,10,10C370.332,381.545,365.847,386.031,360.333,386.031z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M456.499,228h-18.5v-79.6c0-30.002-24.409-54.411-54.411-54.411h-53.626v-7.346c0-15.945-11.49-29.248-26.624-32.086
			L251.712,2.929c-3.905-3.905-10.237-3.905-14.143,0c-3.905,3.905-3.905,10.237,0,14.143l36.917,36.917h-48.684l-51.06-51.06
			c-3.905-3.905-10.237-3.905-14.143,0c-3.905,3.905-3.905,10.237,0,14.143l39.45,39.45c-11.765,4.941-20.05,16.58-20.05,30.12
			v7.346H128.41c-30.002,0.001-54.411,24.409-54.411,54.412V228h-18.5c-11.855,0-21.5,9.645-21.5,21.5v57
			c0,11.855,9.645,21.5,21.5,21.5h18.5v57.364c0,30.532,20.047,56.459,47.667,65.339V478c0,18.748,15.252,34,34,34s34-15.252,34-34
			v-24.012h132.667V478c0,18.748,15.252,34,34,34s34-15.252,34-34v-27.296c27.619-8.88,47.667-34.807,47.667-65.339V328h18.5
			c11.855,0,21.5-9.645,21.5-21.5v-57C477.999,237.645,468.354,228,456.499,228z M73.999,308h-18.5c-0.827,0-1.5-0.673-1.5-1.5v-57
			c0-0.827,0.673-1.5,1.5-1.5h18.5V308z M199.999,86.642c0-6.978,5.676-12.654,12.654-12.654h84.655
			c6.978,0,12.654,5.676,12.654,12.654v7.346H199.999V86.642z M93.999,238v-79.6h28c5.523,0,10-4.477,10-10c0-5.523-4.477-10-10-10
			H95.486c4.294-14.112,17.427-24.411,32.924-24.411h255.178c15.497,0,28.63,10.299,32.925,24.411h-91.241
			c-0.003,0-112.272,0-112.272,0c-5.523,0-10,4.477-10,10c0,5.523,4.477,10,10,10h88.125L151.523,308H93.999V238z M169.666,478
			c0,7.72-6.28,14-14,14c-7.72,0-14-6.28-14-14v-24.036c0.32,0.004,0.636,0.024,0.958,0.024h27.042V478z M370.333,478
			c0,7.72-6.28,14-14,14c-7.72,0-14-6.28-14-14v-24.012h27.042c0.321,0,0.637-0.02,0.958-0.024V478z M417.999,385.364
			c0,26.812-21.812,48.625-48.624,48.625H142.623c-26.812,0-48.624-21.813-48.624-48.624V328h324V385.364z M417.999,238v70H179.808
			l149.601-149.6h71.048l-74.296,74.296c-3.905,3.905-3.905,10.237,0,14.143c1.953,1.953,4.512,2.929,7.071,2.929
			s5.119-0.976,7.071-2.929l77.696-77.696V238z M457.999,306.5c0,0.827-0.673,1.5-1.5,1.5h-18.5v-60h18.5c0.827,0,1.5,0.673,1.5,1.5
			V306.5z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M280.999,366.031h-50c-5.523,0-10,4.478-10,10c0,5.522,4.477,10,10,10h50c5.523,0,10-4.478,10-10
			C290.999,370.509,286.522,366.031,280.999,366.031z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M174.739,141.33c-1.86-1.86-4.44-2.93-7.07-2.93s-5.21,1.07-7.07,2.93c-1.86,1.86-2.93,4.44-2.93,7.07
			s1.07,5.21,2.93,7.07c1.86,1.86,4.44,2.93,7.07,2.93s5.21-1.07,7.07-2.93c1.87-1.86,2.93-4.44,2.93-7.07
			S176.609,143.19,174.739,141.33z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M313.579,259.42c-1.86-1.86-4.44-2.93-7.07-2.93c-2.64,0-5.21,1.07-7.07,2.93c-1.86,1.86-2.93,4.439-2.93,7.07
			c0,2.64,1.07,5.21,2.93,7.069c1.86,1.87,4.44,2.931,7.07,2.931s5.21-1.061,7.07-2.931c1.86-1.859,2.93-4.439,2.93-7.069
			C316.509,263.859,315.439,261.28,313.579,259.42z"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
}
