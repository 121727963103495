import React from 'react';
import { useHistory } from 'react-router-dom';
import BackIcon from '../icons/components/BackIcon';

const isDarkTheme = window.localStorage.getItem('user-theme') === 'dark-theme';

export default function BackButton({
  className = '',
  overRideBackUrl,
  ...props
}) {
  const history = useHistory();

  function goBackCallback(history) {
    if (overRideBackUrl) {
      history.replace(overRideBackUrl);
      return;
    }
    if (history.length > 2) {
      // if history is not empty, go back:
      history.goBack();
    } else {
      // otherwise go to home page
      history.replace('/');
    }
  }

  return (
    <div
      className={`back-btn ${className}`}
      onClick={goBackCallback.bind(null, history)}
    >
      <BackIcon width='18px' color={isDarkTheme ? '#FFFF' : '#464646'} />
    </div>
  );
}
