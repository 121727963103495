import React, { useState, useEffect } from "react";
import "../styles/inputComponent.scss";

export default function ({
  className,
  style,
  callback,
  placeholderText,
  preFilledValue,
  name,
  ...props
}) {
  const [stopId, setStopId] = useState("");

  useEffect(() => {
    setStopId(preFilledValue);
  }, [preFilledValue]);
  return (
    <div className={`input-wrapper ${className}`} ref={props.headerRef}>
      {!!name && <span className="input-name">{name}</span>}
      <div className="input-container">
        <input
          type="text"
          placeholder={placeholderText}
          value={stopId}
          onChange={(evt) => setStopId(evt.target.value)}
        ></input>
        <button
          disabled={!stopId}
          className="btn btn-primary"
          onClick={() => callback(stopId)}
        >
          Ok
        </button>
      </div>
    </div>
  );
}
