import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import DraggableModal from '../components/DraggingModal';
import NextStopsdetails from '../components/NextStopsdetails';
import MapWrapper from '../utils/MapWrapper';
import UseUserInactive from '../utils/UseUserInactive';
import BackButton from '../components/BackButton';
import { useHistory } from 'react-router-dom';
import LocateMe from '../components/LocateMe';
import UseTracking from '../utils/UseTracking';
import UsePageActive from '../utils/UsePageActive';

const colorThere = '#60b929';
const colorBack = '#2997b9';

export default function HomePage({ ...props }) {
  let { vehicleId } = useParams();
  let [routeId, setRouteId] = useState();
  let [vehicleStops, setVehicleStops] = useState(null);
  let [dynamicRouteData, setDynamicRouteData] = useState();
  let [staticRouteData, setStaticRouteData] = useState({ route_long_name: '' });
  let [vehicleMarker, setVehicleMarker] = useState();
  let [toggleModal, setToggleModal] = useState(false);
  let [vehicleDirection, setVehicleDirection] = useState();
  let [filteredNextStops, setFilteredNextStops] = useState([]);
  const isCancelled = useRef(false);
  const isPageActive = UsePageActive();

  let mainMapInstance = MapWrapper();
  const history = useHistory();

  const visibleContentRef = useRef(null);
  const headerRef = useRef(null);

  let { setTimerPaused } = UseUserInactive({
    callback: fetchDynamic,
    userTimeout: 180,
    callbackTimeout: 5,
    pageName: 'VEHICLE_DETAILS_PAGE',
  });

  function fetchDynamic() {
    // return fetch(`https://stg-api.lad.lviv.ua/vehicle/${vehicleId}`)
    return fetch(`https://api.lad.lviv.ua/vehicle/${vehicleId}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (isCancelled.current) {
          return;
        }
        setRouteId(data.routeId);
        setDynamicRouteData(data);
        setVehicleDirection(data.direction);
      });
  }

  useEffect(() => {
    mainMapInstance.init({
      showCurrentLocation: true,
      container: 'route-details-map',
      zoom: 17,
    });
    fetchDynamic();
    return () => {
      isCancelled.current = true;
    };
  }, []);

  useEffect(() => {
    setTimerPaused(!isPageActive);
  }, [isPageActive]);

  useEffect(() => {
    if (!routeId) {
      return;
    }
    fetch(`https://api.lad.lviv.ua/routes/static/${routeId}`)
      // fetch(`https://stg-api.lad.lviv.ua/routes/static/${routeId}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (isCancelled.current) {
          return;
        }
        setStaticRouteData(data);
        setVehicleStops(data.stops);
      });
  }, [routeId]);

  useEffect(() => {
    drawTransportLine();
    drawLineStops();
  }, [staticRouteData, vehicleDirection]);

  useEffect(() => {
    if (!dynamicRouteData) {
      return;
    }
    if (!vehicleMarker) {
      drawBus();
    } else {
      updateDrawnVehicle();
    }
    calculateNextStops();
    dynamicRouteData.direction && setVehicleDirection(dynamicRouteData.direction);
  }, [dynamicRouteData, vehicleStops]);

  function calculateNextStops() {
    if (!vehicleStops || !dynamicRouteData.arrivals) {
      return;
    }

    let stopsNames = vehicleStops[0].concat(vehicleStops[1]).reduce((acc, item) => {
      acc[item['code']] = item.name;
      return acc;
    }, {});

    let filteredStops = dynamicRouteData.arrivals.map((stopItem) => {
      return { ...stopItem, name: stopsNames[stopItem.code] };
    });

    setFilteredNextStops(filteredStops);
  }

  function drawTransportLine() {
    if (!staticRouteData.shapes || vehicleDirection === undefined) {
      return;
    }

    staticRouteData.shapes[vehicleDirection] &&
      staticRouteData.shapes[vehicleDirection].length &&
      mainMapInstance.drawPolyLine({
        coordinates: staticRouteData.shapes[vehicleDirection],
        color: vehicleDirection ? colorBack : colorThere,
        fitBounds: true,
      });
  }

  function drawLineStops() {
    if (!staticRouteData.stops || vehicleDirection === undefined) {
      return;
    }
    staticRouteData.stops[vehicleDirection].forEach((item, index) =>
      drawNode(
        item.loc,
        vehicleDirection ? 'back' : 'there',
        item.name,
        index === 0 || index === staticRouteData.stops[vehicleDirection].length - 1,
        item.code,
      ),
    );
  }

  function updateDrawnVehicle() {
    if (dynamicRouteData && dynamicRouteData['location']) {
      let color = dynamicRouteData['direction'] === 1 ? colorBack : colorThere;
      vehicleMarker.setLatLng(dynamicRouteData['location']);
      vehicleMarker.getElement().querySelector('.marker-bus-inner').style.transform = `rotate(${
        dynamicRouteData.bearing - 90
      }deg)`;
      vehicleMarker.getElement().querySelector('.marker-bus-inner').style.backgroundColor = color;
    }
  }

  function drawBus() {
    if (!dynamicRouteData) {
      return;
    }

    let bearing = dynamicRouteData.bearing - 90;
    let color = dynamicRouteData['direction'] === 1 ? colorBack : colorThere;
    setTimeout(() => {
      mainMapInstance.setMapLocation(dynamicRouteData['location']);
    }, 100);

    setVehicleMarker(
      mainMapInstance.createMarker({
        location: dynamicRouteData.location,
        iconSize: [25, 25],
        html: `<div class="marker-bus-inner" style="transform: rotate(${bearing}deg); background-color: ${color}">&#10148;</div></div>`,
        className: `bus-marker bus-marker-id-`,
        callBack: () => console.log('click'),
      }),
    );
  }

  function nodeClickCallBack(clickedCode) {
    let foundStop = null;

    foundStop = staticRouteData.stops[0].filter(({ code }) => clickedCode === code);
    if (!foundStop.length) {
      foundStop = staticRouteData.stops[1].filter(({ code }) => clickedCode === code);
    }
    UseTracking({
      eventCategory: 'page-click',
      eventAction: 'vahicle_details_transfer_click',
      eventLabel: 'clickedCode',
    });
    history.push(`/stops/${clickedCode}`);
  }

  function drawNode(coordinates, direction, name, alwaysShow, code) {
    mainMapInstance.createMarker({
      location: coordinates,
      iconSize: [8, 8],
      html: `<span class="shown-zoom-mt-16">${name}</span>`,
      className: `bus-stop-node ${!alwaysShow && 'shown-zoom-mt-14'} ${direction}`,
      callBack: () => {
        history.push(`/${code}`);
      },
    });
  }

  return (
    <div className='fixed-full-screen'>
      <div id='route-details-map' className='route-details-map'></div>
      <BackButton className='page-back-button' />
      <DraggableModal
        toggleModal={toggleModal}
        visibleContentRef={visibleContentRef}
        headerRef={headerRef}
        isExpandable
        elementOverModal={<LocateMe updateUserLocation={mainMapInstance.updateUserLocation} />}
      >
        <NextStopsdetails
          vehicleDirection={vehicleDirection}
          headerRef={headerRef}
          visibleContentRef={visibleContentRef}
          routeId={staticRouteData.route_short_name}
          stopsData={filteredNextStops}
          name={staticRouteData.route_long_name}
          vehicleType={staticRouteData.vehicle_type || staticRouteData.type || 'bus'}
          nodeClickCallBack={nodeClickCallBack}
          subHeaderCallBack={() => {
            UseTracking({
              eventCategory: 'page-click',
              eventAction: 'vahicle_details_button_click',
              eventLabel: 'see_all_vehicles_on_route',
            });
            history.push(`/route/${staticRouteData.route_short_name}`);
          }}
        />
      </DraggableModal>
    </div>
  );
}
