import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import BackButton from '../components/BackButton';
import DraggableModal from '../components/DraggingModal';
import LocateMe from '../components/LocateMe';
import RouteDetails from '../components/RouteDetails';
import Schedule from '../icons/components/Schedule';
import '../styles/routeDetails.scss';
import MapWrapper from '../utils/MapWrapper';
import UsePageActive from '../utils/UsePageActive';
import UseTracking from '../utils/UseTracking';
import UseUserInactive from '../utils/UseUserInactive';

const colorThere = '#60b929';
const colorBack = '#2997b9';

export default function HomePage({ ...props }) {
  const { routeId } = useParams();
  let [dynamicRouteData, setDynamicRouteData] = useState([]);
  let [staticRouteData, setStaticRouteData] = useState({ route_long_name: '' });
  let [transportOnMap, setTransportOnMap] = useState([]);
  let [numberOfTransportItems, setNumberOfTransportItems] = useState('...');
  let [toggleModal, setToggleModal] = useState(false);
  let mainMapInstance = MapWrapper();
  const history = useHistory();
  const isCancelled = useRef(false);

  const visibleContentRef = useRef(null);
  const headerRef = useRef(null);
  const isPageActive = UsePageActive();

  let { setTimerPaused } = UseUserInactive({
    callback: fetchDynamic,
    userTimeout: 40,
    callbackTimeout: 5,
    pageName: 'ROUTE_DETAILS_PAGE',
  });

  function fetchDynamic(time) {
    return fetch(`https://api.lad.lviv.ua/routes/dynamic/${routeId}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (isCancelled.current) {
          return;
        }
        setDynamicRouteData(data);
        setNumberOfTransportItems(data.length);
      });
  }

  useEffect(() => {
    setTimerPaused(!isPageActive);
  }, [isPageActive, setTimerPaused]);

  useEffect(() => {
    mainMapInstance.init({
      showCurrentLocation: true,
      container: 'route-details-map',
    });

    fetch(`https://api.lad.lviv.ua/routes/static/${routeId}`)
      // fetch(`https://stg-api.lad.lviv.ua/routes/static/${routeId}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (isCancelled.current) {
          return;
        }
        setStaticRouteData(data);
      });
    fetchDynamic();

    return () => {
      isCancelled.current = true;
    };
  }, []);

  useEffect(() => {
    drawTransportLine();
    drawLineStops();
    addSEOtext();
  }, [staticRouteData]);

  function addSEOtext() {
    let type = { bus: 'Автобус', tram: 'Трамвай', trol: 'Тролейбус' };
    let types = { bus: 'автобуса', tram: 'трамвая', trol: 'тролейбуса' };

    if (!staticRouteData || !staticRouteData.type) {
      return;
    }
    document.title = `${type[staticRouteData.type]} ${staticRouteData.route_short_name}, Львів`;
    document
      .querySelector('[name="description"]')
      .setAttribute(
        'content',
        `Маршрут ${types[staticRouteData.type]} ${
          staticRouteData.route_short_name
        } на мапі, карті Львова, розкалад транспорту, зупинки, час прибуття`,
      );
  }

  useEffect(() => {
    if (!transportOnMap.length) {
      drawBus();
    } else {
      updateDrawnTransport();
    }
  }, [dynamicRouteData]);

  function drawTransportLine() {
    if (!staticRouteData.shapes) {
      return;
    }

    staticRouteData.shapes[0].length &&
      mainMapInstance.drawPolyLine({
        coordinates: staticRouteData.shapes[0],
        color: colorThere,
        fitBounds: true,
      });

    staticRouteData.shapes[1].length &&
      mainMapInstance.drawPolyLine({
        coordinates: staticRouteData.shapes[1],
        color: colorBack,
        fitBounds: true,
      });
  }

  function drawLineStops() {
    if (!staticRouteData.stops) {
      return;
    }
    staticRouteData.stops[0].forEach((item, index) =>
      drawNode(
        item.loc,
        'there',
        item.name,
        index === 0 || index === staticRouteData.length - 1,
        item.code,
      ),
    );
    staticRouteData.stops[1].forEach((item, index) =>
      drawNode(
        item.loc,
        'back',
        item.name,
        index === 0 || index === staticRouteData.length - 1,
        item.code,
      ),
    );
  }

  function updateDrawnTransport() {
    transportOnMap.forEach((marker, index) => {
      if (dynamicRouteData && dynamicRouteData[index] && dynamicRouteData[index]['location']) {
        let color = dynamicRouteData[index]['direction'] === 1 ? colorBack : colorThere;
        marker.setLatLng(dynamicRouteData[index]['location']);
        marker.getElement().querySelector('.marker-bus-inner').style.transform = `rotate(${
          dynamicRouteData[index].bearing - 90
        }deg)`;
        marker.getElement().querySelector('.marker-bus-inner').style.backgroundColor = color;
      }
    });
  }

  function drawBus() {
    let allNodes = [];

    dynamicRouteData.forEach((item) => {
      let bearing = item.bearing - 90;
      let color = item['direction'] === 1 ? colorBack : colorThere;

      allNodes.push(
        mainMapInstance.createMarker({
          location: item.location,
          iconSize: [25, 25],
          html: `<div class="marker-bus-inner" style="transform: rotate(${bearing}deg); background-color: ${color}">&#10148;</div></div>`,
          className: `bus-marker bus-marker-id-`,
          callBack: () => history.push(`/vehicle/${item.id}`),
        }),
      );
    });
    setTransportOnMap(allNodes);
  }

  function nodeClickCallBack(clickedCode) {
    let foundStop = null;

    foundStop = staticRouteData.stops[0].filter(({ code }) => clickedCode === code);
    if (!foundStop.length) {
      foundStop = staticRouteData.stops[1].filter(({ code }) => clickedCode === code);
    }
    mainMapInstance.setMapLocation(foundStop[0].loc, 17);
    setToggleModal(false);
  }

  function drawNode(coordinates, direction, name, alwaysShow, code) {
    mainMapInstance.createMarker({
      location: coordinates,
      iconSize: [8, 8],
      html: `<span class="shown-zoom-mt-16">${name}</span>`,
      className: `bus-stop-node ${!alwaysShow && 'shown-zoom-mt-14'} ${direction}`,
      callBack: () => {
        history.push(`/${code}`);
      },
    });
  }

  return (
    <div className='fixed-full-screen'>
      <div id='route-details-map' className='route-details-map'></div>
      <BackButton className='page-back-button' />
      <DraggableModal
        toggleModal={toggleModal}
        visibleContentRef={visibleContentRef}
        headerRef={headerRef}
        isExpandable
        elementOverModal={
          <>
            <Link
              to={`/route/${staticRouteData.route_short_name}/schedule`}
              onClick={() => {
                UseTracking({
                  eventCategory: 'page-click',
                  eventAction: 'route_map_click',
                  eventLabel: 'schedule_button_click',
                });
              }}
            >
              <Schedule width={32} height={32} className={'schedule-icon'} />
              <div class="schedule-label">&#9757;&#65039; розклад руху</div>
            </Link>
            <LocateMe updateUserLocation={mainMapInstance.updateUserLocation} />
          </>
        }
      >
        <RouteDetails
          headerRef={headerRef}
          visibleContentRef={visibleContentRef}
          allStopsData={staticRouteData}
          smallSubtitle={`Кількіть транспорту на маршруті: ${numberOfTransportItems}`}
          nodeClickCallBack={nodeClickCallBack}
        />
      </DraggableModal>
    </div>
  );
}
