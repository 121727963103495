import React, { useState, useEffect, useRef } from "react";
import InfoBlock from "../components/InfoBlock";
import PageHeader from "../components/PageHeader";
import "../styles/lineCard.scss";
import { useHistory } from "react-router-dom";
import LineCard from "../components/LineCard";
import UseTracking from "../utils/UseTracking";

export default function () {
  const [favoriteStops, setFavoriteStops] = useState([]);
  const history = useHistory();

  useEffect(() => {
    setFavoriteStops(JSON.parse(window.localStorage.getItem("favoriteStops")));
  }, []);

  function renderStops() {
    if (favoriteStops && favoriteStops.length) {
      return favoriteStops.map((item, index) => (
        <LineCard
          className="mb-1 mt-10"
          data={{
            route: item.code,
            end_stop: `Зупинка - ${item.name}`,
            color: "#6385a9",
          }}
          isFirst={false}
          key={index}
          onClickCallback={() => {
            UseTracking({
              eventCategory: "page-click",
              eventAction: "go_to_saved_bus_stop",
              eventLabel: item.code,
            });
            history.push(`/stops/${item.code}`);
          }}
          removeTimeLeft
        />
      ));
    }
  }

  return (
    <div className="container">
      <PageHeader
        title="Улюблені"
        subTitle="Збережені зупинки для швидкого доступу"
      />

      <InfoBlock
        hide={favoriteStops && favoriteStops.length}
        className={`mt-15 `}
      >
        <span>
          Натисніть значок "сердечка" на сторінці зупинки для швидкого доступу
          тут.
        </span>
      </InfoBlock>
      <div className="mt-25">{renderStops()}</div>
    </div>
  );
}
