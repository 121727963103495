import React, { useState, useEffect } from 'react';

export default function useGlobalMessages() {
  const [lastRead, setLastRead] = useState(null);
  const [messageToShow, setMessageToShow] = useState();

  function getMessages(dateFromLocal) {
    let options = {
      since: dateFromLocal
    };

    fetch(`https://api.lad.lviv.ua/messages?since=${options.since}`).then(
      (response) =>
        response.json().then((data) => {
          if (!data) {
            return;
          } else {
            setMessageToShow(data);
            let newDateAfterResponse = new Date().toString();

            window.localStorage.setItem(
              'last-read-global-message',
              newDateAfterResponse
            );
          }
        })
    );
  }

  useEffect(() => {
    let dateFromLocal = window.localStorage.getItem('last-read-global-message');

    if (dateFromLocal) {
      setLastRead(dateFromLocal);
    } else {
      let newDate = new Date().toString();
      setLastRead(newDate);
      window.localStorage.setItem('last-read-global-message', newDate);
      dateFromLocal = newDate;
    }
    getMessages(dateFromLocal);
  }, []);

  return messageToShow;
}
