import React, { useState, useEffect, useRef } from "react";
import BusIcon from "../icons/components/BusIcon";
import TramIcon from "../icons/components/TramIcon";
import TrolleyIcon from "../icons/components/TrolleyIcon";
import "../styles/routeDetails.scss";
import isLightColor from '../utils/isLightColor';

const colorThere = "#60b929";
const colorBack = "#2997b9";

export default function ({
  stopsData,
  routeId,
  nodeClickCallBack,
  visibleContentRef,
  headerRef,
  smallSubtitle,
  name,
  subHeaderCallBack,
  vehicleDirection,
  vehicleType,
  transfers
}) {
  let [currentTime, setCurrentTime] = useState(new Date());
  let [absoluteTimeFormat, setAbsoluteTimeFormat] = useState(false);
  let themeColor = vehicleDirection ? colorBack : colorThere;

  useEffect(() => {
    setCurrentTime(new Date());
  }, [stopsData])

  function handleNodeClick(code) {
    nodeClickCallBack(code);
  }

  function normalizeTime (time) {
    return time < 10 ? '0' + time: time;
  }


  function renderTimeLeft (arrival) {
    let arrivalTime = new Date(arrival);
    let diffSeconds = Math.round((arrivalTime.getTime() - currentTime.getTime())/1000);

    let relativeMinutes = Math.round(diffSeconds / 60);
    let relativeHours = Math.floor(diffSeconds / 3600);

    let hours = arrivalTime.getHours();
    let minutes = arrivalTime.getMinutes();

    function calculateTimeLeft () {
      if (relativeHours > 0) {
        return 'за ' + relativeHours + 'г. ' + Math.round((diffSeconds - (relativeHours * 3600))/60)  + 'хв';
      } else if (!!relativeMinutes) {
        return 'за ' + relativeMinutes + 'хв';
      } else {
        return 'менше 1хв'
      }
    }

    function clickCallBack (e) {
      e.stopPropagation();
      e.preventDefault();
      setAbsoluteTimeFormat(!absoluteTimeFormat)
    }

    if (absoluteTimeFormat) {
      return (<span className="time-left" onClick={clickCallBack}>{`${normalizeTime(hours)}:${normalizeTime(minutes)}`}</span>)
    } else {
      return (<span className="time-left" onClick={clickCallBack}>{calculateTimeLeft()}</span>)
    }

  }

  function renderGraphTree({
    name,
    index,
    back,
    code,
    arrival,
    shouldbeVisibleInModal,
    transfers
  }) {
    if (!arrival) {return };

    return (
      <div
        className="details-content"
        key={name + index}
        {...(shouldbeVisibleInModal && { ref: visibleContentRef })}
      >
        <div className="route-graph">
          <div className="route-node">
            <div className="route-node-left" onClick={() => handleNodeClick(code)}>
              <span className={`route-node-indicator ${back && "back"}`}></span>
              <div className="route-node-name">
                <div>{name}</div>
                <div className='transfer-wrapper'>
                  {transfers.map((transfer, index) => (
                    <span 
                      key={transfer.id, index}
                      className='route-node-transfer'
                      style={{ backgroundColor: transfer.color, color: isLightColor(transfer.color) ? 'black': 'white' }}
                    >
                      {transfer.route}
                    </span>
                  ))}
                </div>
              </div>
              <span className={`route-node-connector ${back && "back"}`}></span>
            </div>
            <div className="route-node-right">
              {renderTimeLeft(arrival)}
            </div>
          </div>
        </div>
      </div>
    );
  }

  let numberOfVisibleStops = 2;
  if (stopsData.length < 3) {
    numberOfVisibleStops = 1;
  };
  if  (stopsData.length < 2) {
    numberOfVisibleStops = 1;
  }

  return (
    <div>
      <div className="details-header" ref={headerRef}>
        {(vehicleType === 'bus') && <BusIcon width="30px" color={themeColor} className="mr-1" />}
        {(vehicleType === 'tram') && <TramIcon width="35px" color={themeColor} className="mr-1" />}
        {(vehicleType === 'trol') && <TrolleyIcon width="30px" color={themeColor} className="mr-1" /> }
        <div className={`details-title mr-2 ${vehicleDirection ? 'back' : ''}`}>{routeId}</div>
        <div className="details-subtitle-block">
          <div className="details-subtitle">{name}</div>
          <button className="btn btn-outlined small" onClick={subHeaderCallBack}>
            Весь транспорт на {routeId}
          </button>
        </div>
      </div>
      <div className="graphs-content column">
          {stopsData &&
            stopsData.map(({ name, code, arrival, transfers }, index) =>
              renderGraphTree({
                name,
                index,
                back: !!vehicleDirection,
                code,
                arrival,
                shouldbeVisibleInModal: index === numberOfVisibleStops - 1,
                transfers
              })
            )}

      </div>
    </div>
  );
}
